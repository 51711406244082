import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ThemeButton, Typography, Image } from 'components/atoms';
import { Box } from 'rebass/styled-components';

export const NavContainer = styled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.transparent};
  display: flex;
  height: 5rem;
  position: relative;
  transition: all 0.2s;

  ${({ scrolled, theme }) =>
    scrolled &&
    css`
      background-color: ${theme.colors.dark};
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    `}
`;

export const NavBrand = styled.div`
  height: 3.5rem;
  text-decoration: none;
`;

export const DesktopMenu = styled.ul`
  display: none;
  flex-direction: row;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const MobileNav = styled(Box)`
  display: flex;
  position: relative;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MobileMenuToggler = styled.div`
  cursor: pointer;
  display: block;
  position: relative;
  span {
    background: white;
    border-radius: 2px;
    content: '';
    display: block;
    height: 2px;
    position: relative;
    transition: 0.3s all;
    width: 2rem;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  &.active {
    span {
      &:first-child {
        top: 6px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        top: -4px;
        transform: rotate(-45deg);
      }
    }
  }
`;

export const MobileMenu = styled.ul`
  background: #000;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  min-width: 15rem;
  opacity: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -100px;
  transition: 0.3s all;

  &.active {
    opacity: 1;
    pointer-events: auto;
    top: 100%;
  }
`;

export const MenuItem = styled.li`
  align-items: center;
  display: inline-flex;
  padding: 0.75rem 1.5rem;
  @media screen and (min-width: 768px) {
    padding: 1rem 1.5rem;
  }
`;

export const MenuLink = styled(NavLink)`
  display: inline-flex;
  font-size: 1.4rem;
  letter-spacing: 0.06rem;
  text-decoration: none;
  width: 100%;
  &,
  &:visited {
    color: #ffffff;
  }
`;

export const UploadButton = styled(ThemeButton)`
  height: 3rem;
`;

export const ProfileWrapper = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  ${Typography.Text} {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.4rem;
    font-weight: bold;
    margin-right: 1rem;
  }
`;

export const Avatar = styled(Image)`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
`;

export const ProfileMenu = styled.ul`
  display: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: calc(100% + 1rem);

  ${({ active }) =>
    active &&
    css`
      display: block;
    `}
`;

export const ProfileMenuItem = styled.li`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }
`;
