import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SelectorWrapper, SelectorImage } from './styled';

export const ImageSelector = memo(({ activeImage, images, onSelect }) => {
  return (
    <SelectorWrapper>
      {images.slice(0, 3).map((img, i) => (
        <SelectorImage alt="" onClick={() => onSelect(img)} active={activeImage === img} src={img} key={i} />
      ))}
    </SelectorWrapper>
  );
});

ImageSelector.displayName = 'ImageSelector';

ImageSelector.propTypes = {
  activeImage: PropTypes.string,
  images: PropTypes.array,
  onSelect: PropTypes.func,
};

ImageSelector.defaultProps = {
  activeImage: '',
  images: [],
  onSelect: () => {},
};
