import React, { memo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form as FormikWrapper } from 'formik';
import * as Yup from 'yup';
import { Modal } from 'components/molecules';
import { Form, Typography, ThemeButton, FormInput, FormTextArea } from 'components/atoms';
import MultiImageInput from 'react-multiple-image-input';
import { ErrorDiv } from '../../atoms/ErrorDiv';
import { uploadImages } from 'utils/upload';
import { useAuth0 } from 'hooks/auth-hooks';
import { createSwapRequest } from 'services/swap-request-service';
import { toast } from 'react-toastify';
import { useGetSwapRequestsHook } from 'hooks/swap-requests-hook';
import { ADD_SWAP_REQUEST } from 'actions/swap-request-actions';
import { handleError } from 'utils/error-handler';
import { Flex } from 'rebass/styled-components';
import { useRef } from 'react';
import { consolesCollection } from 'utils/constants';
import { ALL } from 'utils/constants';
import { consolesMap } from 'utils/constants';

const FormWrapper = styled.form`
  margin-bottom: 6rem;
`;

const SubmitButton = styled(ThemeButton)`
  min-width: 10rem;
`;

const CheckBoxItem = styled(Flex)`
  align-items: center;
  &:not(:last-child) {
    margin-right: 4rem;
  }
`;

const validationSchema = Yup.object({
  offering: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .required('You need to let people know what you are offering'),
  wants: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .required('You need to let people know what you want'),
});

const initialValues = {
  offering: '',
  wants: '',
  note: '',
};

const CreateDealModal = memo(({ isOpen, toggle }) => {
  const [files, setFiles] = useState({});
  const [category, setCategory] = useState('ps4');
  const [imageError, setImageError] = useState('');
  const { dispatch } = useGetSwapRequestsHook();
  const { getTokenSilently } = useAuth0();
  const formRef = useRef();

  const resetForm = () => {
    if (formRef?.current?.resetForm) formRef.current.resetForm();
    setFiles({});
    setCategory('ps4');
  };

  const createRequest = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      if (!files[0]) {
        setImageError('Add at least one image that shows what you are offering');
        return;
      }
      const imageObjects = await uploadImages(files);
      const imageUrls = imageObjects.map(
        image => `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload/${image.public_id}`,
      );
      const requestData = {
        ...values,
        images: imageUrls,
        category,
      };
      const token = await getTokenSilently();
      const savedRequest = await createSwapRequest(requestData, token);
      dispatch({ type: ADD_SWAP_REQUEST, swapRequest: savedRequest });
      toast.success('Request created');
      resetForm();
      toggle();
    } catch (e) {
      setSubmitting(false);
      handleError(e);
    }
  };

  const crop = {
    unit: '%',
    aspect: 4 / 3,
    width: '100',
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph color="white">POST A DEAL</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <FormWrapper>
          <Formik
            innerRef={formRef}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={createRequest}
          >
            {({ isSubmitting }) => (
              <FormikWrapper>
                <Form.Group>
                  <FormInput placeholder="WHAT I HAVE" name="offering" />
                </Form.Group>

                <Form.Group>
                  <FormInput placeholder="WHAT I WANT" name="wants" />
                </Form.Group>

                <Form.Group>
                  <FormTextArea placeholder="ADDITIONAL NOTES" name="note" />
                </Form.Group>

                <Form.Group>
                  <Flex>
                    {consolesCollection
                      .filter(console => console !== ALL)
                      .map((console, index) => (
                        <CheckBoxItem key={index} onClick={() => setCategory(console)}>
                          <Form.Checkbox isChecked={category === console} />
                          <Typography.Text color="offWhite6" fontSize="small">
                            {consolesMap[console]}
                          </Typography.Text>
                        </CheckBoxItem>
                      ))}
                  </Flex>
                </Form.Group>

                <Form.Group style={{ display: 'flex' }}>
                  <MultiImageInput images={files} setImages={setFiles} cropConfig={{ crop }} />
                  {imageError ? <ErrorDiv>{imageError}</ErrorDiv> : null}
                </Form.Group>

                <SubmitButton disabled={isSubmitting} loading={isSubmitting} type="submit">
                  POST
                </SubmitButton>
              </FormikWrapper>
            )}
          </Formik>
        </FormWrapper>
      </Modal.Body>
    </Modal>
  );
});

CreateDealModal.displayName = 'CreateDealModal';

CreateDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default CreateDealModal;
