import React from 'react';

function SvgBackArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 17" fill="none" {...props}>
      <path
        d="M24.48 7.98H1.777l6.924-6.924a.52.52 0 10-.737-.737L.153 8.132a.52.52 0 000 .736l7.812 7.813a.52.52 0 00.737 0 .52.52 0 000-.737L1.778 9.021H24.48a.52.52 0 100-1.042z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgBackArrow;
