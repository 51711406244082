import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form as FormikWrapper } from 'formik';
import * as Yup from 'yup';
import { Modal } from 'components/molecules';
import { Form, Typography, ThemeButton, FormInput } from 'components/atoms';
import { useAuth0 } from 'hooks/auth-hooks';
import { confirmPhoneNumber } from 'services/user-service';
import { handleError } from 'utils/error-handler';
import { toast } from 'react-toastify';

const FormWrapper = styled.form`
  margin-bottom: 2rem;
`;

const SubmitButton = styled(ThemeButton)`
  min-width: 10rem;
`;

const validationSchema = Yup.object({
  otp: Yup.string().length(6, 'Must be 6 characters'),
});

const initialValues = {
  otp: '',
};

const VerifyOTPModal = memo(({ isOpen, toggle }) => {
  const { token, setUser } = useAuth0();

  const submitRequest = async (value, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const user = await confirmPhoneNumber(value, token);

      setUser(user);
      toast.success('phone number verified');
      toggle(false);
    } catch (e) {
      handleError(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph color="white">VERIFY OTP</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <FormWrapper>
          <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={submitRequest}>
            {({ isSubmitting }) => (
              <FormikWrapper>
                <Form.Group>
                  <FormInput placeholder="OTP" name="otp" />
                </Form.Group>

                <SubmitButton disabled={isSubmitting} loading={isSubmitting} type="submit">
                  VERIFY
                </SubmitButton>
              </FormikWrapper>
            )}
          </Formik>
        </FormWrapper>
      </Modal.Body>
    </Modal>
  );
});

VerifyOTPModal.displayName = 'VerifyOTPModal';

VerifyOTPModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  requestId: PropTypes.string,
};

export default VerifyOTPModal;
