import React from 'react';

function SvgEdit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <g clipPath="url(#edit_svg__clip0)" fill="#fff">
        <path d="M13.844 9.423a.374.374 0 00-.373.373v3.317c0 .62-.502 1.12-1.12 1.121H1.867c-.619 0-1.12-.502-1.12-1.12V3.377c0-.618.501-1.12 1.12-1.12h3.317a.374.374 0 100-.748H1.868A1.87 1.87 0 000 3.378v9.736a1.87 1.87 0 001.868 1.867H12.35a1.87 1.87 0 001.868-1.867V9.796a.374.374 0 00-.374-.373z" />
        <path d="M14.07.55a1.681 1.681 0 00-2.377 0L5.028 7.213a.374.374 0 00-.096.165l-.877 3.164a.374.374 0 00.46.46l3.164-.877a.373.373 0 00.165-.096l6.664-6.665a1.683 1.683 0 000-2.377L14.07.549zM5.842 7.456l5.454-5.455 1.76 1.76L7.6 9.215l-1.76-1.76zm-.352.705l1.406 1.406-1.944.538.538-1.944zm8.49-5.325l-.396.396-1.76-1.76.397-.395a.934.934 0 011.32 0l.44.438a.935.935 0 010 1.32z" />
      </g>
      <defs>
        <clipPath id="edit_svg__clip0">
          <path fill="#fff" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgEdit;
