import { css } from 'styled-components';

const breakpoints = {
  sm: '36em',
  md: '48em',
  lg: '62em',
  xl: '75em',
};

const mq = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const darkTheme = {
  breakpoints: [...Object.values(breakpoints)],
  mq,
  buttons: {
    small: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      padding: '0.5rem 1.0rem',
    },
    normal: {
      fontSize: '1.4rem',
      fontWeight: '500',
      height: '4.0rem',
      padding: '0.5rem 1.0rem',
    },
    large: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      height: '5.0rem',
      padding: '1.4rem 3.0rem',
    },
  },
  colors: {
    azure4: '#1da1f2',
    black: '#000',
    dark: '#111111',
    darkBlue: '#3b5998',
    darkOverlay: 'rgba(0,0,0,0.6)',
    darkRed: '#96281b',
    grey: '#151515',
    grey2: '#D8D8D8',
    inherit: 'inherit',
    lightBlue: '#1da1f2',
    offWhite4: 'rgba(255,255,255,0.4)',
    offWhite6: 'rgba(255,255,255,0.6)',
    offWhite8: 'rgba(255,255,255,0.8)',
    primary: '#730f14',
    primaryDisabled: '#26080b',
    primaryRed: '#ff0e1f',
    red: '#af171f',
    viridian: '#219653',
    white: '#FFFFFF',
  },
  font: {
    align: {
      center: 'center',
      initial: 'initial',
      left: 'left',
      right: 'right',
    },
    size: {
      big: '1.8rem',
      inherit: 'inherit',
      normal: '1.6rem',
      small: '1.4rem',
    },
    weight: {
      normal: 'normal',
      medium: 'medium',
      bold: 'bold',
    },
  },

  heading: {
    desktop: {
      h1: '5.0rem',
      h2: '3.0rem',
      h3: '2.5rem',
      h4: '2.0rem',
    },
    mobile: {
      h1: '3.5rem',
      h2: '3.0rem',
      h3: '2.5rem',
      h4: '2.0rem',
    },
  },
};
