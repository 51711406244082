import { auth0ClientRequest, request } from './http-client';

export const registerUser = ({ firstName, lastName, ...userData }) => {
  return auth0ClientRequest
    .post(`/dbconnections/signup`, {
      ...userData,
      given_name: firstName,
      family_name: lastName,
      username: `${userData.email.split('@')[0]}${Math.floor(Math.random() * 100)}`,
      connection: process.env.REACT_APP_AUTH0_DB_CONNECTION,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    })
    .then(res => res.data);
};

export const updateAuth0User = async ({ userId, ...userData }, getToken) => {
  const token = await getToken({
    audience: process.env.REACT_APP_AUTH_USER_MANAGEMENT_AUDIENCE,
    scope: 'update:users update:users_app_metadata update:current_user_metadata',
  });

  const response = await auth0ClientRequest.patch(
    `/api/v2/users/${userId}`,
    {
      ...userData,
      connection: process.env.REACT_APP_AUTH0_DB_CONNECTION,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    },
    { headers: { authorization: `Bearer ${token}` } },
  );

  return response.data;
};

export const getAuthUser = async auth0Client => {
  const auth0user = await auth0Client.getUser();

  const res = await request.get(`/user/one?email=${auth0user.email}`);

  return res.data;
};
