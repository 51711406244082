import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import PropTypes from 'prop-types';

export const Button = styled.button`
  -webkit-appearance: none;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  white-space: nowrap;
  ${props => {
    if (props.size) {
      return css`
        font-size: ${props.theme.buttons[props.size].fontSize};
        font-weight: ${props.theme.buttons[props.size].fontWeight};
        height: ${props.theme.buttons[props.size].height};
        padding: ${props.theme.buttons[props.size].padding};
      `;
    }
  }}

  ${props => {
    if (props.full) {
      return css`
        width: 100%;
      `;
    }
  }}

  ${props => {
    if (props.loading) {
      return css`
        color: transparent !important;
        pointer-events: none;
        position: relative;
        &::after {
          content: '';
          animation: rotate 500ms infinite linear;
          border: 2px solid #fff;
          border-radius: 50%;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
          display: block;
          height: 1.4em;
          width: 1.4em;
          position: absolute;
          left: calc(50% - (1.4em / 2));
          top: calc(50% - (1.4em / 2));
          -webkit-transform-origin: center;
          transform-origin: center;
          position: absolute !important;
        }
      `;
    }
  }}
`;

export const ThemeButton = styled(Button)`
  background-color: ${props => props.theme.colors[props.color]};
  &,
  &:visited {
    color: #fff;
  }

  &:disabled,
  &[disabled] {
    color: ${props => props.theme.colors.primaryDisabled};
    cursor: default;
  }

  &:hover {
    ${props => {
      const colorHex = props.theme.colors[props.color];
      const hexRegex = /^#[0-9a-f]{3,6}$/i;

      if (hexRegex.test(colorHex)) {
        return css`
          background-color: ${lighten(0.05, colorHex)};
          transform: translateY(-2px);
        `;
      }

      return css`
        opacity: 0.9;
        transform: translateY(-2px);
      `;
    }};
  }

  ${props => {
    if (props.color === 'white') {
      return css`
        &,
        &:visited {
          color: ${props.theme.colors.dark};
        }
      `;
    }
  }}

  ${props => {
    if (props.raised) {
      return css`
        box-shadow: 0 4px 0 ${darken(0.2, props.theme.colors[props.color])};

        &:hover {
          box-shadow: 0 2px 0 ${darken(0.2, props.theme.colors[props.color])};
          transform: translateY(2px);
        }

        &:active {
          box-shadow: none;
          transform: translateY(4px);
        }
      `;
    }
  }}
`;

export const OutlineThemeButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors[props.color]};
  &,
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }

  &:hover {
    transform: translateY(-2px);
    opacity: 0.8;
  }
`;

Button.defaultProps = {
  size: 'normal',
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'large']).isRequired,
};

ThemeButton.propTypes = {
  color: PropTypes.string,
};
ThemeButton.defaultProps = {
  color: 'primary',
};

OutlineThemeButton.propTypes = {
  color: PropTypes.string,
};

OutlineThemeButton.defaultProps = {
  color: 'primary',
};
