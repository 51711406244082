import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';

export const Row = ({ children, ...rest }) => (
  <Flex flexWrap="wrap" mx="-1.5rem" {...rest}>
    {children}
  </Flex>
);

Row.defaultProps = {
  className: '',
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
