import React, { useReducer, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  LOADING,
  GET_SWAP_REQUESTS_FAILURE,
  GET_SWAP_REQUESTS_SUCCESS,
  DELETE_SWAP_REQUEST,
  ADD_SWAP_REQUEST,
  ADD_INTEREST,
  RESET_SWAP_REQUESTS,
} from 'actions/swap-request-actions';
import { getSwapRequests } from 'services/swap-request-service';
import { handleError } from 'utils/error-handler';

const initialValue = { swapRequests: [], count: 0, loading: false, hasMore: true };
export const swapRequestsContext = React.createContext(initialValue);

const reducer = (state, action) => {
  let swapRequestIndex, swapRequests;
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case GET_SWAP_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        swapRequests: [...state.swapRequests, ...action.swapRequests],
        count: action.count,
        hasMore: state.swapRequests.length + action.swapRequests.length < action.count,
      };
    case GET_SWAP_REQUESTS_FAILURE:
      return { ...state, loading: false };
    case ADD_SWAP_REQUEST:
      return {
        ...state,
        loading: false,
        swapRequests: [...state.swapRequests, action.swapRequest],
        count: state.count + 1,
      };
    case DELETE_SWAP_REQUEST:
      return {
        ...state,
        loading: false,
        swapRequests: state.swapRequests.filter(request => request._id !== action.id),
        count: state.count - 1,
      };
    case ADD_INTEREST:
      swapRequests = [...state.swapRequests];

      swapRequestIndex = swapRequests.findIndex(r => r._id === action.requestId);

      if (swapRequestIndex >= 0) {
        swapRequests[swapRequestIndex].swapInterests.push(action.swapInterest);
      }

      return {
        ...state,
        loading: false,
        swapRequests,
      };
    case RESET_SWAP_REQUESTS:
      return {
        ...state,
        swapRequests: [],
        count: 0,
        hasMore: true,
      };
    default:
      throw new Error('Unrecognized action');
  }
};

export const SwapRequestsController = props => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  const fetchSwapRequests = useCallback(
    query => {
      dispatch({ type: LOADING });
      return getSwapRequests(query)
        .then(data => dispatch({ type: GET_SWAP_REQUESTS_SUCCESS, swapRequests: data.swapRequests, count: data.count }))
        .catch(e => handleError(e) && dispatch({ type: GET_SWAP_REQUESTS_FAILURE }));
    },
    [dispatch],
  );

  const services = useMemo(() => ({ fetchSwapRequests }), [fetchSwapRequests]);

  const value = useMemo(() => ({ dispatch, state, services }), [state, dispatch, services]);

  return <swapRequestsContext.Provider value={value}>{props.children}</swapRequestsContext.Provider>;
};

SwapRequestsController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
