export const PS4 = 'ps4';
export const XBOX = 'xbox';
export const XBOX_ONE = 'xbox_one';
export const PS5 = 'ps5';
export const XBOX_SERIES_X = 'xbox_series_x';
export const ALL = 'all';

export const consolesMap = {
  [ALL]: 'ALL',
  [PS4]: 'PS4',
  [PS5]: 'PS5',
  [XBOX_ONE]: 'XBOX ONE',
  [XBOX_SERIES_X]: 'XBOX SERIES X',
};

export const consolesCollection = [ALL, PS4, PS5, XBOX_ONE, XBOX_SERIES_X];
