import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { ThemeButton, OutlineThemeButton, Logo } from 'components/atoms';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import { useAuth0 } from 'hooks/auth-hooks';
import {
  DesktopMenu,
  MenuItem,
  MenuLink,
  MobileMenu,
  MobileMenuToggler,
  MobileNav,
  NavBrand,
  NavContainer,
  UploadButton,
  ProfileWrapper,
  Avatar,
  ProfileMenu,
  ProfileMenuItem,
} from './styled';
import { Typography } from 'components/atoms';
import { Container } from 'components/atoms/Container';
import { useRef } from 'react';

const Profile = withRouter(({ loading, logout, user, history }) => {
  const ready = user && !loading;
  const [active, setActive] = useState(false);
  const ref = useRef();
  const toggleActive = () => !loading && setActive(v => !v);

  useClickAway(ref, () => setActive(false));

  return ready ? (
    <ProfileWrapper ref={ref} onClick={toggleActive}>
      <Typography.Text>{user.name}</Typography.Text>

      <Avatar src={user.photoUrl} />

      <ProfileMenu active={active}>
        <ProfileMenuItem onClick={() => history.push('/profile')}>PROFILE</ProfileMenuItem>
        <ProfileMenuItem onClick={logout}>LOGOUT</ProfileMenuItem>
      </ProfileMenu>
    </ProfileWrapper>
  ) : null;
});

export const Nav = ({ style, toggleUploadModal }) => {
  const [active, setActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const offset = window.pageYOffset;
      setScrolled(offset > 60);
    };

    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const { loading, isAuthenticated, loginWithRedirect, user, logout } = useAuth0();

  return (
    <NavContainer scrolled={scrolled} style={style}>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <NavBrand as={Link} to="/">
            <Logo />
          </NavBrand>
          <DesktopMenu>
            {/* <MenuItem>
              <MenuLink className="link link__default" to="/how-it-works">HOW IT WORKS</MenuLink>
            </MenuItem> */}
            <MenuItem>
              <MenuLink className="link link__default" to="/deals">
                DEALS
              </MenuLink>
            </MenuItem>

            <MenuItem>
              {isAuthenticated ? (
                <>
                  <UploadButton onClick={toggleUploadModal}>Upload Deal</UploadButton>
                </>
              ) : (
                <OutlineThemeButton
                  onClick={() =>
                    loginWithRedirect({
                      redirect_uri: window.location.origin,
                    })
                  }
                  color="white"
                  disabled={loading}
                  loading={loading}
                  size="small"
                >
                  LOGIN
                </OutlineThemeButton>
              )}
            </MenuItem>

            {isAuthenticated ? (
              <MenuItem>
                <Profile loading={loading} user={user} logout={() => logout({ returnTo: window.location.origin })} />
              </MenuItem>
            ) : (
              <MenuItem>
                <ThemeButton as={NavLink} to="/signup" size="small" disabled={loading} loading={loading}>
                  SIGN UP
                </ThemeButton>
              </MenuItem>
            )}
          </DesktopMenu>
          <MobileNav>
            <MobileMenuToggler
              onClick={() => setActive(!active)}
              className={cx({
                active,
              })}
            >
              <span />
              <span />
              <span />
            </MobileMenuToggler>
            <MobileMenu
              className={cx({
                active,
              })}
            >
              {/* <MenuItem>
                <MenuLink className="link link__default" to="/how-it-works">HOW IT WORKS</MenuLink>
              </MenuItem> */}

              <MenuItem>
                <MenuLink className="link link__default" to="/deals">
                  DEALS
                </MenuLink>
              </MenuItem>

              {isAuthenticated ? (
                <>
                  <MenuItem>
                    <MenuLink className="link link__default" to="/profile">
                      PROFILE
                    </MenuLink>
                  </MenuItem>

                  <MenuItem>
                    <UploadButton onClick={toggleUploadModal}>Upload Deal</UploadButton>
                  </MenuItem>
                </>
              ) : (
                <MenuItem>
                  <OutlineThemeButton
                    color="white"
                    loading={loading}
                    size="small"
                    onClick={() =>
                      loginWithRedirect({
                        redirect_uri: window.location.origin,
                      })
                    }
                    disabled={loading}
                  >
                    LOGIN
                  </OutlineThemeButton>
                </MenuItem>
              )}
              {isAuthenticated ? (
                <MenuItem>
                  <MenuLink
                    className="link link__default"
                    disabled={loading}
                    loading={loading}
                    onClick={() => logout({ returnTo: window.location.origin })}
                    to="#"
                  >
                    LOGOUT
                  </MenuLink>
                </MenuItem>
              ) : null}

              {!loading && !isAuthenticated && (
                <MenuItem>
                  <MenuLink className="link link__default" to="/signup">
                    SIGN UP
                  </MenuLink>
                </MenuItem>
              )}
            </MobileMenu>
          </MobileNav>
        </Box>
      </Container>
    </NavContainer>
  );
};

Nav.defaultProps = {
  style: {},
  toggleUploadModal: () => {},
};

Profile.propTypes = {
  loading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

Nav.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  toggleUploadModal: PropTypes.func,
};
