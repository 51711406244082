import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { ErrorDiv } from './ErrorDiv';

export const Select = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: transparent;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSI0MXB4IiB2aWV3Qm94PSIwIDAgMjQgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjEgKDc4MTM2KSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5zb3J0LXVwLWFuZC1kb3duLWFycm93cy1jb3VwbGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsLW9wYWNpdHk9IjAuNiI+CiAgICAgICAgPGcgaWQ9InNvcnQtdXAtYW5kLWRvd24tYXJyb3dzLWNvdXBsZSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIzLjY5NiwyNC4wNzYgQzIzLjc4NCwyNC4yMzYgMjMuNzc2LDI0LjQzIDIzLjY3NSwyNC41ODYgTDEzLjM5NSw0MC40NDkgQzEzLjMwNCw0MC41ODggMTMuMTU0LDQwLjY3MyAxMi45ODgsNDAuNjc4IEMxMi45ODQsNDAuNjc4IDEyLjk4LDQwLjY3OCAxMi45NzMsNDAuNjc4IEMxMi44MTYsNDAuNjc4IDEyLjY2Myw0MC42MDIgMTIuNTcsNDAuNDczIEwwLjk5OCwyNC42MDkgQzAuODg4LDI0LjQ1OSAwLjg3MSwyNC4yNTUgMC45NTcsMjQuMDg4IEMxLjA0MiwyMy45MiAxLjIxNCwyMy44MTU5NzA2IDEuNDAxLDIzLjgxNTk3MDYgTDIzLjI1NiwyMy44MTU5NzA2IEMyMy40NDMsMjMuODE0IDIzLjYwOSwyMy45MTQgMjMuNjk2LDI0LjA3NiBaIE0xLjQwMSwxNi44NjUgTDIzLjI1NiwxNi44NjUgQzIzLjI2NCwxNi44NjUgMjMuMjczLDE2Ljg2NSAyMy4yNzcsMTYuODY1IEMyMy41NTIsMTYuODY1IDIzLjc3NywxNi42NCAyMy43NzcsMTYuMzY1IEMyMy43NzcsMTYuMjA5IDIzLjcwNywxNi4wNyAyMy41OTMsMTUuOTc3IEwxMi4wODYsMC4yMDUgQzExLjk4OSwwLjA3MiAxMS44MjEsMC4wMDIgMTEuNjY4LDAgQzExLjUwMywwLjAwNSAxMS4zNTMsMC4wOSAxMS4yNjIsMC4yMjkgTDAuOTgyLDE2LjA5NCBDMC44ODEsMTYuMjQ2IDAuODc3LDE2LjQ0NCAwLjk2MSwxNi42MDYgQzEuMDUsMTYuNzY1IDEuMjE4LDE2Ljg2NSAxLjQwMSwxNi44NjUgWiIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
  background-position: calc(100% - 1.5rem) center;
  background-repeat: no-repeat;
  background-size: 12px;
  border-radius: 0;
  border: 2px solid ${props => props.theme.colors.offWhite6};
  color: ${props => props.theme.colors.offWhite6};
  display: block;
  font-size: 16px;
  height: 5rem;
  line-height: 1.6rem;
  margin: 0;
  outline: none;
  padding: 1.5rem;
  transition: all 0.2s;
  width: 100%;

  &:focus {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSI0MXB4IiB2aWV3Qm94PSIwIDAgMjQgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjEgKDc4MTM2KSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5zb3J0LXVwLWFuZC1kb3duLWFycm93cy1jb3VwbGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ic29ydC11cC1hbmQtZG93bi1hcnJvd3MtY291cGxlIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMjMuNjk2LDI0LjA3NiBDMjMuNzg0LDI0LjIzNiAyMy43NzYsMjQuNDMgMjMuNjc1LDI0LjU4NiBMMTMuMzk1LDQwLjQ0OSBDMTMuMzA0LDQwLjU4OCAxMy4xNTQsNDAuNjczIDEyLjk4OCw0MC42NzggQzEyLjk4NCw0MC42NzggMTIuOTgsNDAuNjc4IDEyLjk3Myw0MC42NzggQzEyLjgxNiw0MC42NzggMTIuNjYzLDQwLjYwMiAxMi41Nyw0MC40NzMgTDAuOTk4LDI0LjYwOSBDMC44ODgsMjQuNDU5IDAuODcxLDI0LjI1NSAwLjk1NywyNC4wODggQzEuMDQyLDIzLjkyIDEuMjE0LDIzLjgxNTk3MDYgMS40MDEsMjMuODE1OTcwNiBMMjMuMjU2LDIzLjgxNTk3MDYgQzIzLjQ0MywyMy44MTQgMjMuNjA5LDIzLjkxNCAyMy42OTYsMjQuMDc2IFogTTEuNDAxLDE2Ljg2NSBMMjMuMjU2LDE2Ljg2NSBDMjMuMjY0LDE2Ljg2NSAyMy4yNzMsMTYuODY1IDIzLjI3NywxNi44NjUgQzIzLjU1MiwxNi44NjUgMjMuNzc3LDE2LjY0IDIzLjc3NywxNi4zNjUgQzIzLjc3NywxNi4yMDkgMjMuNzA3LDE2LjA3IDIzLjU5MywxNS45NzcgTDEyLjA4NiwwLjIwNSBDMTEuOTg5LDAuMDcyIDExLjgyMSwwLjAwMiAxMS42NjgsMCBDMTEuNTAzLDAuMDA1IDExLjM1MywwLjA5IDExLjI2MiwwLjIyOSBMMC45ODIsMTYuMDk0IEMwLjg4MSwxNi4yNDYgMC44NzcsMTYuNDQ0IDAuOTYxLDE2LjYwNiBDMS4wNSwxNi43NjUgMS4yMTgsMTYuODY1IDEuNDAxLDE2Ljg2NSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
    border: 2px solid ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.white};
  }
`;

export const FormikDropdown = ({ Component, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <Component
        style={{
          appearence: 'none',
          backgroundColor: 'transparent',
          border: '2px solid rgba(255,255,255,0.6)',
          borderRadius: '0',
          color: 'rgba(255,255,255,0.6)',
          display: 'block',
          fontSize: '16px',
          height: '5rem',
          margin: '0',
          outline: 'none',
          padding: '0 1.5rem',
          transition: 'all 0.2s',
          width: '100%',
        }}
        {...field}
        {...props}
        value={meta.value}
        onChange={value => helpers.setValue(value)}
      />
      {meta.touched && meta.error ? <ErrorDiv>{meta.error}</ErrorDiv> : null}
    </>
  );
};
