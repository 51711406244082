import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

export const Col = ({ xs, sm, md, lg, xl, children, style, ...props }) => {
  const getWidth = width => {
    if (width) return width / 12;

    return null;
  };

  return (
    <Box
      px="1.5rem"
      width={[getWidth(xs), getWidth(sm), getWidth(md), getWidth(lg), getWidth(xl)]}
      style={style}
      {...props}
    >
      {children}
    </Box>
  );
};

Col.defaultProps = {
  lg: null,
  md: null,
  sm: null,
  xl: null,
  xs: 12,
  style: {},
};

Col.propTypes = {
  children: PropTypes.node.isRequired,
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.objectOf(PropTypes.any),
};
