import React from 'react';
import PropTypes from 'prop-types';

function SvgAdd({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" fill="none" {...props}>
      <path d="M49 8.5v-8H0v47h7v7h48v-46h-6zm-47 37v-43h45v6H7v37H2zm51 7H9v-42h44v42z" fill={fill} />
      <path d="M42 30.5H32v-10a1 1 0 10-2 0v10H20a1 1 0 100 2h10v10a1 1 0 102 0v-10h10a1 1 0 100-2z" fill={fill} />
    </svg>
  );
}

SvgAdd.propTypes = {
  fill: PropTypes.string,
};

SvgAdd.defaultProps = {
  fill: '#fff',
};

export default SvgAdd;
