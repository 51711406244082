import { authRequest, request } from './http-client';
import { objectToUrlQuery } from 'utils/service-utils';

export const createSwapRequest = (data, token) => {
  return authRequest(token)
    .post('/swap/create-request', data)
    .then(res => res.data);
};

export const getSwapRequests = (queryConfig, token) => {
  const requestHandler = queryConfig.requiresAuth === 'true' ? authRequest(token) : request;

  return requestHandler.get(`/swap/fetch?${objectToUrlQuery(queryConfig)}`).then(res => res.data);
};

export const getSwapRequest = requestId => {
  return request.get(`/swap/one/${requestId}`).then(res => res.data);
};

export const indicateSwapInterest = (requestId, data, token) => {
  return authRequest(token)
    .post(`/swap/indicate-interest/${requestId}`, data)
    .then(res => res.data);
};

export const acceptSwapInterest = ({ interestId, requestId }, token) => {
  return authRequest(token)
    .post(`/swap/accept/${requestId}`, { interestId })
    .then(res => res.data);
};

export const deleteSwapRequest = (requestId, token) => {
  return authRequest(token)
    .delete(`/swap/one/${requestId}`)
    .then(res => res.data);
};
