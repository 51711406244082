import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const chase = keyframes`
  100% { transform: rotate(360deg); } 
`;

const chaseDot = keyframes`
80%, 100% { transform: rotate(360deg); } 
`;

const chaseDotBefore = keyframes`
50% {
  transform: scale(0.4); 
} 100%, 0% {
  transform: scale(1.0); 
} 
`;

const Spinner = styled.div`
  animation: ${chase} 2.5s infinite linear both;
  height: 40px;
  position: relative;
  width: 40px;

  .sk-chase-dot {
    animation: ${chaseDot} 2s infinite ease-in-out both;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .sk-chase-dot:before {
    animation: ${chaseDotBefore} 2s infinite ease-in-out both;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 100%;
    content: '';
    display: block;
    height: 25%;
    width: 25%;
  }

  .sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
  }
  .sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
  }
  .sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  .sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
  }
  .sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
  }
  .sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
  }
  .sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
  }
  .sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
  }
  .sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
  }
  .sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
  }
  .sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
  }
  .sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.dark};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: ${({ fit }) => (fit ? 'absolute' : 'fixed')};
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Loader = ({ fit }) => {
  return (
    <Wrapper fit={fit}>
      <Spinner>
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
      </Spinner>
    </Wrapper>
  );
};

Loader.propTypes = {
  fit: PropTypes.bool,
};

Loader.defaultProps = {
  fit: false,
};
