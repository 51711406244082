import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    max-width: 160rem;
    padding-left: 12rem;
    padding-right: 12rem;

    ${({ fluid }) =>
      fluid &&
      css`
        padding-left: 9rem;
        padding-right: 9rem;
      `}
  }
`;
