import React from 'react';
import { Typography } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faComments, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useBreakpoint } from 'hooks/useBreakpoints';
import { ActionBox, CardWrapper, DetailBox, ActionButton, IconBox, ActionOutlineButton } from './styled';
import { Poster } from './Poster';
import avatar from '../../../assets/img/avatar.png';

export const ContactCard = ({ backgroundColor, onReviewClick, user }) => {
  const breakpoint = useBreakpoint();

  const renderNumberActions = () => {
    if (user?.phoneNumber && breakpoint === 'xs') {
      return (
        <ActionButton as="a" href={`tel:${user.phoneNumber}`} className="action-btn" color="azure4">
          <FontAwesomeIcon icon={faPhone} /> CALL
        </ActionButton>
      );
    }

    return null;
  };

  return (
    <CardWrapper backgroundColor={backgroundColor} justifyContent="space-between">
      <>
        <DetailBox>
          <Poster src={user?.photoUrl || avatar} />
          <Box display="flex" flexDirection="column">
            <Typography.Heading color="white" type="h4" style={{ marginBottom: '2rem' }}>
              {user?.name}
            </Typography.Heading>

            {user?.location ? (
              <IconBox>
                <Typography.Text fontSize="small" color="offWhite6">
                  <FontAwesomeIcon icon={faMapMarker} /> {`${user?.location?.city}, ${user?.location?.state}`}
                </Typography.Text>
              </IconBox>
            ) : null}

            {user?.phoneNumber ? (
              <IconBox>
                <Typography.Text color="offWhite6">
                  <FontAwesomeIcon icon={faPhone} /> {user?.phoneNumber}
                </Typography.Text>
              </IconBox>
            ) : null}
          </Box>
        </DetailBox>
        <ActionBox flexDirection="column" justifyContent="space-between">
          <ActionButton as="a" href={`mailto:${user?.email}`} className="action-btn" color="primary">
            <FontAwesomeIcon icon={faComments} /> SEND MESSAGE
          </ActionButton>
          {renderNumberActions()}
          <ActionOutlineButton onClick={onReviewClick} className="action-btn" color="white">
            WRITE REVIEW
          </ActionOutlineButton>
        </ActionBox>
      </>
    </CardWrapper>
  );
};

ContactCard.propTypes = {
  backgroundColor: PropTypes.string,
  onReviewClick: PropTypes.func,
  user: PropTypes.object,
};
