import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const FormGroup = styled(Box)`
  margin-bottom: 2rem;
`;

FormGroup.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
};
