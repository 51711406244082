import React from 'react';
import PropTypes from 'prop-types';

function SvgTwitter({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 10" fill="none" {...props}>
      <path
        d="M12.656 1.188a5.06 5.06 0 01-1.453.39 2.516 2.516 0 001.11-1.39c-.485.289-1.032.5-1.602.609A2.517 2.517 0 008.867 0a2.522 2.522 0 00-2.523 2.523c0 .196.023.391.062.579A7.173 7.173 0 011.203.46a2.522 2.522 0 00.781 3.375 2.542 2.542 0 01-1.14-.32v.03c0 1.227.867 2.243 2.023 2.477a2.668 2.668 0 01-1.14.047c.32 1 1.25 1.727 2.359 1.75A5.054 5.054 0 01.953 8.898c-.21 0-.406-.007-.61-.03A7.13 7.13 0 004.22 10c4.64 0 7.18-3.844 7.18-7.18 0-.11 0-.218-.008-.328.492-.36.921-.804 1.265-1.304z"
        fill={fill}
      />
    </svg>
  );
}

SvgTwitter.propTypes = {
  fill: PropTypes.string,
};

SvgTwitter.defaultProps = {
  fill: '#fff',
};

export default SvgTwitter;
