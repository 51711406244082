export const LOADING = 'LOADING';

export const GET_SWAP_REQUESTS_SUCCESS = 'GET_SWAP_REQUESTS_SUCCESS';

export const GET_SWAP_REQUESTS_FAILURE = 'GET_SWAP_REQUESTS_FAILURE';

export const ADD_SWAP_REQUEST = 'ADD_SWAP_REQUEST';

export const ADD_INTEREST = 'ADD_INTEREST';

export const DELETE_SWAP_REQUEST = 'DELETE_SWAP_REQUEST';

export const RESET_SWAP_REQUESTS = 'RESET_SWAP_REQUESTS';
