import React from 'react';
import PropTypes from 'prop-types';

function SvgInstagram({ fill, fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M10.86 0H4.14A4.144 4.144 0 000 4.14v6.72A4.144 4.144 0 004.14 15h6.72A4.144 4.144 0 0015 10.86V4.14A4.144 4.144 0 0010.86 0zm2.81 10.86a2.812 2.812 0 01-2.81 2.809H4.14a2.812 2.812 0 01-2.81-2.809V4.14a2.812 2.812 0 012.81-2.81h6.72a2.812 2.812 0 012.809 2.81v6.72z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
      <path
        d="M7.5 3.635A3.87 3.87 0 003.635 7.5 3.87 3.87 0 007.5 11.365 3.87 3.87 0 0011.365 7.5 3.87 3.87 0 007.5 3.635zm0 6.4A2.537 2.537 0 014.966 7.5 2.537 2.537 0 017.5 4.966 2.537 2.537 0 0110.034 7.5 2.537 2.537 0 017.5 10.034zM11.527 2.506a.98.98 0 00-.976.976c0 .257.105.509.287.69a.983.983 0 00.69.286.986.986 0 00.69-.285.98.98 0 000-1.38.98.98 0 00-.69-.287z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

SvgInstagram.propTypes = {
  fill: PropTypes.string,
  fillOpacity: PropTypes.number,
};

SvgInstagram.defaultProps = {
  fill: '#fff',
  fillOpacity: 0.8,
};

export default SvgInstagram;
