import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Footer, Nav } from 'components/molecules';
import CreateDealModal from './CreateDealModal';
import { SwapRequestsController } from 'contexts/swap-context';

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  transition: all 0.2s;
  width: 100%;
`;

const PageWrapper = withRouter(({ children, location }) => {
  const [showNav, setNav] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(v => !v);

  useEffect(() => {
    const { pathname } = location;
    setNav(!pathname.includes('/signup'));
  }, [location, location.pathname]);
  return (
    <PageContent>
      {showNav && (
        <Nav
          style={{ position: 'fixed', left: 0, right: 0, width: '100%', zIndex: 3 }}
          toggleUploadModal={toggleModal}
        />
      )}
      {children}
      <Footer />
      <SwapRequestsController>
        <CreateDealModal isOpen={modal} toggle={toggleModal} />
      </SwapRequestsController>
    </PageContent>
  );
});

export default PageWrapper;
