import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { ErrorDiv } from './ErrorDiv';
import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const Input = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: transparent;
  border-radius: 0;
  border: 2px solid ${props => props.theme.colors.offWhite6};
  color: ${props => props.theme.colors.offWhite6};
  display: block;
  font-size: 16px;
  height: 5rem;
  margin: 0;
  outline: none;
  padding: 1.5rem;
  transition: all 0.2s;
  width: 100%;

  &:focus {
    border: 2px solid ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.white};
  }
`;

export const FormInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Input {...field} {...props} />
      {meta.touched && meta.error ? <ErrorDiv>{meta.error}</ErrorDiv> : null}
    </>
  );
};

export const PhoneInput = ({ ...props }) => {
  const [, meta, helpers] = useField(props);
  return (
    <>
      <PhoneInputComponent
        inputProps={{
          name: props.name,
          id: props.name,
        }}
        inputStyle={{
          appearence: 'none',
          backgroundColor: 'transparent',
          borderRadius: '0',
          border: '2px solid rgba(255,255,255,0.6)',
          color: 'rgba(255,255,255,0.6)',
          display: 'block',
          fontSize: '16px',
          height: '5rem',
          margin: '0',
          outline: 'none',
          transition: 'all 0.2s',
          width: '100%',
        }}
        buttonStyle={{
          backgroundColor: 'transparent',
        }}
        autoFormat
        {...props}
        value={meta.value}
        onChange={value => helpers.setValue(value)}
      />
      {meta.touched && meta.error ? <ErrorDiv>{meta.error}</ErrorDiv> : null}
    </>
  );
};
