import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShareDealModal from 'components/organisms/ShareDealModal';

export const ShareDealContext = React.createContext({});

export const ShareDealProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');

  const toggleModal = (dealId = '') => {
    if (isOpen) {
      setIsOpen(false);
      setId('');
    } else {
      setIsOpen(true);
      setId(dealId);
    }
  };

  return (
    <ShareDealContext.Provider
      value={{
        isOpen,
        id,
        toggleModal,
      }}
    >
      {children}
      <ShareDealModal isOpen={isOpen} toggle={toggleModal} dealId={id} set={setIsOpen} />
    </ShareDealContext.Provider>
  );
};

ShareDealProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
