import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const HeadingTag = ({ type: Component, children, className, ...props }) => (
  <Component className={className} {...props}>
    {children}
  </Component>
);

export const Heading = styled(HeadingTag)`
  color: ${props => props.theme.colors[props.color]};
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  display: block;
  font-weight: ${props => props.theme.font.weight[props.fontWeight]};
  margin: 0;
  position: relative;
  text-align: ${props => props.theme.font.align[props.textAlign]};
  ${props => {
    return css`
      font-size: ${props.theme.heading.mobile[props.type]};
      line-height: 1.2;
      @media screen and (min-width: 577px) {
        font-size: ${props.theme.heading.desktop[props.type]};
        line-height: 1.2;
      }
    `;
  }}

  ${props => {
    if (props.underlined) {
      return css`
        &:after {
          background-color: ${props.theme.colors.red};
          content: '';
          height: 0.5rem;
          left: 50%;
          min-width: 20rem;
          position: absolute;
          top: calc(100% + 1rem);
          transform: translateX(-50%) skewX(30deg);
        }
      `;
    }
  }}
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.colors[props.color]};
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  display: block;
  font-size: ${props => props.theme.font.size[props.fontSize]};
  font-weight: ${props => props.theme.font.weight[props.fontWeight]};
  line-height: 1.2;
  margin: 0;
  text-align: ${props => props.theme.font.align[props.textAlign]};
  text-decoration: ${({ decoration }) => decoration};
`;

export const Text = styled.span`
  color: ${props => props.theme.colors[props.color]};
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  display: inline-block;
  font-size: ${props => props.theme.font.size[props.fontSize]};
  font-weight: ${props => props.theme.font.weight[props.fontWeight]};
  line-height: 1.2;
  text-align: ${props => props.theme.font.align[props.textAlign]};
  text-decoration: ${({ decoration }) => decoration};
`;

HeadingTag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']).isRequired,
};

Heading.defaultProps = {
  color: 'grey',
  fontWeight: 'normal',
  textAlign: 'initial',
  type: 'h1',
};

Paragraph.defaultProps = {
  color: 'grey',
  decoration: 'none',
  fontSize: 'normal',
  fontWeight: 'normal',
  textAlign: 'initial',
};

Text.defaultProps = {
  color: 'inherit',
  decoration: 'none',
  fontSize: 'inherit',
  fontWeight: 'normal',
  textAlign: 'initial',
};

export default {
  Heading,
  Paragraph,
  Text,
};
