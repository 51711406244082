import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Logo } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import { NavContainer } from './Nav/styled';
import history from '../../history';
import { Button } from 'components/atoms';
import { Grid } from 'components/atoms';

const CloseButton = styled(Button)`
  background: ${props => props.theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 5rem;

  &::before,
  &::after {
    background: ${props => props.theme.colors.black};
    content: '';
    height: 0.2rem;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 2.5rem;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const LogoBox = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  @media screen and (min-width: 576px) {
    justify-content: center;
  }
`;

export const AuthNav = () => {
  return (
    <NavContainer justifyContent="space-between">
      <Grid.Container>
        <Box display="flex">
          <LogoBox>
            <Link to="/">
              <Logo />
            </Link>
          </LogoBox>
        </Box>
      </Grid.Container>
      <CloseButton onClick={history.goBack} />
    </NavContainer>
  );
};
