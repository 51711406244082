import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from 'components/atoms/Container';

const Wrapper = styled.div`
  padding-top: 150px;
`;

const Header = styled.div`
  margin-bottom: 3rem;

  ${({ theme }) => theme.mq.md`
  margin-bottom: 4rem;
  `}
`;

const Tttle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
  text-align: center;

  ${({ theme }) => theme.mq.md`
  font-size: 5rem;
  `}
`;

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;

  ${({ theme }) => theme.mq.md`
  font-size: 3rem;
  `}
`;

const List = styled.div``;

const ListItem = styled.div`
  color: ${({ theme }) => theme.colors.white};
  span {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .title {
    font-weight: bold;
  }

  .subtitle {
    line-height: 3rem;
    white-space: pre-line;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

Tttle.defaultProps = {
  type: 'h1',
};

Subtitle.defaultProps = {
  type: 'h2',
};

export const StaticPageWrapper = ({ data, subtitle, title }) => {
  return (
    <Wrapper>
      <Container>
        <Header>
          <Tttle>{title}</Tttle>
          <Subtitle>{subtitle}</Subtitle>
        </Header>

        <List>
          {data.map((item, index) => (
            <ListItem key={index}>
              <span className="title">
                {index + 1}. {item.title}
              </span>
              <span className="subtitle">{item.content}</span>
            </ListItem>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};

StaticPageWrapper.propTypes = {
  data: PropTypes.array,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
