import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useEffect } from 'react';

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;

  label {
    &:not(:last-child) {
      margin-left: 1rem;
    }
  }
`;

export const StarRatingComponent = props => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (props.value !== null) {
      setValue(props.value);
    }
  }, [props.value]);

  const onChange = inputValue => {
    const { editing, value } = props;

    if (!editing) {
      return;
    }

    if (value != null) {
      return;
    }

    setValue(inputValue);
  };

  const onStarClick = (index, value, name, e) => {
    e.stopPropagation();

    const { onStarClick, editing } = props;

    if (!editing) {
      return;
    }

    onStarClick && onStarClick(index, value, name, e);
  };

  const renderStars = () => {
    const { name, starCount, starColor, emptyStarColor, editing } = props;

    const starStyles = (i, value) => ({
      float: 'right',
      cursor: editing ? 'pointer' : 'default',
      color: value >= i ? starColor : emptyStarColor,
    });
    const radioStyles = {
      display: 'none',
      position: 'absolute',
      marginLeft: -9999,
    };

    // populate stars
    let starNodes = [];

    for (let i = starCount; i > 0; i--) {
      const id = `${name}_${i}`;
      const starNodeInput = (
        <input
          key={`input_${id}`}
          style={radioStyles}
          className="dv-star-rating-input"
          type="radio"
          name={name}
          id={id}
          value={i}
          checked={value === i}
          onChange={() => onChange(i, name)}
        />
      );
      const starNodeLabel = (
        <label
          key={`label_${id}`}
          style={starStyles(i, value)}
          className={'dv-star-rating-star ' + (value >= i ? 'dv-star-rating-full-star' : 'dv-star-rating-empty-star')}
          htmlFor={id}
          onClick={e => onStarClick(i, value, name, e)}
        >
          {renderIcon(i, value, name, id)}
        </label>
      );

      starNodes.push(starNodeInput);
      starNodes.push(starNodeLabel);
    }

    return starNodes.length ? starNodes : null;
  };

  const renderIcon = (index, value, name, id) => {
    const { renderStarIcon } = props;

    if (typeof renderStarIcon === 'function') {
      return renderStarIcon(index, value, name, id);
    }

    return (
      <i key={`icon_${id}`} style={{ fontStyle: 'normal' }}>
        &#9733;
      </i>
    );
  };

  const { editing, className } = props;
  const classes = cx(
    'dv-star-rating',
    {
      'dv-star-rating-non-editable': !editing,
    },
    className,
  );

  return <Wrapper className={classes}>{renderStars()}</Wrapper>;
};

StarRatingComponent.propTypes = {
  className: PropTypes.string,
  editing: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onStarClick: PropTypes.func,
  renderStarIcon: PropTypes.func,
  starColor: PropTypes.string,
  starCount: PropTypes.number,
  value: PropTypes.number,
  emptyStarColor: PropTypes.string,
};

StarRatingComponent.defaultProps = {
  starCount: 5,
  editing: true,
  starColor: '#fff',
  emptyStarColor: 'rgba(255,255,255,0.2)',
};
