import React from 'react';
import ContentLoader from 'react-content-loader';
import { useBreakpoint } from 'hooks/useBreakpoints';

const DesktopLoader = props => {
  return (
    <ContentLoader
      backgroundOpacity={0.5}
      animate
      height={160}
      viewBox="0 0 100% 160"
      width={'100%'}
      preserveAspectRatio="xMaxYMin meet"
      {...props}
    >
      <rect x="113" y="8" rx="3" ry="3" width="73" height="10" />
      <rect x="113" y="52" rx="3" ry="3" width="89" height="10" />
      <rect x="113" y="78" rx="3" ry="3" width="69" height="8" />
      <rect x="8" y="8" rx="0" ry="0" width="89" height="90" />
      <rect x="90%" y="8" rx="0" ry="0" width="120" height="22" />
      <rect x="90%" y="48" rx="0" ry="0" width="120" height="22" />
    </ContentLoader>
  );
};

const MobileLoader = props => (
  <ContentLoader backgroundOpacity={0.5} animate height={220} viewBox="0 0 100% 220" width={'100%'} {...props}>
    <rect x="125" y="8" rx="3" ry="3" width="73" height="10" />
    <rect x="125" y="52" rx="3" ry="3" width="89" height="10" />
    <rect x="125" y="78" rx="3" ry="3" width="69" height="8" />
    <rect x="8" y="8" rx="0" ry="0" width="100" height="100" />
    <rect x="8" y="140" rx="0" ry="0" width="100%" height="22" />
    <rect x="8" y="180" rx="0" ry="0" width="100%" height="22" />
  </ContentLoader>
);

export const Loader = props => {
  const breakpoint = useBreakpoint();
  return breakpoint === 'xs' ? <MobileLoader {...props} /> : <DesktopLoader {...props} />;
};
