import React, { memo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'components/molecules';
import { Typography, ThemeButton, OutlineThemeButton } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import { deleteSwapRequest } from 'services/swap-request-service';
import { useAuth0 } from 'hooks/auth-hooks';
import { useGetSwapRequestsHook } from 'hooks/swap-requests-hook';
import { DELETE_SWAP_REQUEST } from 'actions/swap-request-actions';
import { handleError } from 'utils/error-handler';

const Prompt = styled(Box)`
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Buttons = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const DeleteDealModal = memo(({ isOpen, toggle, dealId }) => {
  const { getTokenSilently } = useAuth0();
  const { dispatch } = useGetSwapRequestsHook();
  const [deleting, setDeleting] = useState(false);

  const deleteDeal = async () => {
    try {
      setDeleting(true);
      const token = await getTokenSilently();
      await deleteSwapRequest(dealId, token);
      dispatch({ type: DELETE_SWAP_REQUEST, id: dealId });
      toggle();
    } catch (e) {
      handleError(e);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph color="white">DELETE</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <Prompt>
          <Typography.Paragraph color="offWhite6" textAlign="center" fontSize="small">
            ARE YOU SURE YOU WANT TO DELETE THIS DEAL?
          </Typography.Paragraph>
        </Prompt>

        <Buttons>
          <ThemeButton disabled={deleting} loading={deleting} onClick={deleteDeal} color="primary">
            DELETE
          </ThemeButton>
          <OutlineThemeButton disabled={deleting} onClick={toggle} color="primary">
            CLOST
          </OutlineThemeButton>
        </Buttons>
      </Modal.Body>
    </Modal>
  );
});

DeleteDealModal.displayName = 'DeleteDealModal';

DeleteDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  dealId: PropTypes.string,
};

export default DeleteDealModal;
