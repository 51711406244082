export const objectToUrlQuery = queryParamsObject => {
  let queryParamsString = '';
  Object.keys(queryParamsObject).forEach(key => {
    queryParamsString += `${key}=${queryParamsObject[key]}&`;
  });
  if (queryParamsString) {
    return queryParamsString.slice(0, -1);
  }
  return queryParamsString;
};

export const containsQueryString = url => {
  const pattern = new RegExp(/\?.+=.*/g);

  return pattern.test(url);
};
