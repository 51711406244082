import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'components/molecules';
import { Typography, ThemeButton } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import { FacebookIcon } from 'components/icons';
import { TwitterIcon } from 'components/icons';
import { openInNewTab } from 'utils/share';
import { facebookShare } from 'utils/share';
import { twitterShare } from 'utils/share';

const Prompt = styled(Box)`
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Buttons = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const IconButton = styled(ThemeButton)`
  width: 100%;

  svg {
    margin-right: 0.75rem;
    flex-shrink: 0;
  }
`;

const ShareDealModal = memo(({ isOpen, toggle, dealId }) => {
  const generateSharelink = () => {
    return `https://www.swapurgame.com/deals/${dealId}`;
  };

  const getContent = () => {
    return `Checkout this amazing game deal`;
  };

  const link = generateSharelink();

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph color="white">SHARE</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <Prompt>
          <Typography.Paragraph color="offWhite6" textAlign="center" fontSize="small">
            Share this deal to a friend or group of friends
          </Typography.Paragraph>
        </Prompt>

        <Buttons>
          <IconButton color="darkBlue" onClick={() => openInNewTab(facebookShare(link, getContent()))}>
            <FacebookIcon /> SHARE ON FACEBOOK
          </IconButton>
          <IconButton color="lightBlue" onClick={() => openInNewTab(twitterShare(link, getContent()))}>
            <TwitterIcon />
            SHARE ON TWITTER
          </IconButton>
        </Buttons>
      </Modal.Body>
    </Modal>
  );
});

ShareDealModal.displayName = 'ShareDealModal';

ShareDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  dealId: PropTypes.string,
};

export default ShareDealModal;
