import { useContext, useState, useCallback, useReducer } from 'react';
import { swapRequestsContext } from '../contexts/swap-context';
import { useAuth0 } from './auth-hooks';
import { getSwapRequests } from 'services/swap-request-service';

export const useGetSwapRequestsHook = () => {
  const values = useContext(swapRequestsContext);

  return { ...values.state, dispatch: values.dispatch, ...values.services };
};

export const useToggleIndicateInterestModalHook = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [requestId, setRequestId] = useState(null);

  const toggleIndicateModal = useCallback(
    id => {
      if (!isOpen) {
        setRequestId(id);
        setIsOpen(true);
        return;
      }
      setIsOpen(false);
      setRequestId(null);
    },
    [isOpen],
  );

  return { requestId, isOpen, toggleIndicateModal };
};

export const useToggleAcceptModalHook = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [interest, setInterest] = useState({});

  const toggleAcceptModal = useCallback(
    (interest = {}) => {
      if (!isOpen) {
        setInterest(interest);
        setIsOpen(true);
        return;
      }
      setIsOpen(false);
      setInterest({});
    },
    [isOpen],
  );

  return { interest, isOpen, toggleAcceptModal };
};

export const useGetSwapRequests = query => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'get_deals_success':
        return {
          ...state,
          loading: false,
          count: action.count,
          hasMore: state.swapRequests.length + action.swapRequests.length < action.count,
          swapRequests: [...state.swapRequests, ...action.swapRequests],
        };
      case 'loading':
        return {
          ...state,
          loading: true,
        };
      case 'get_deals_failure':
        return {
          ...state,
          loading: false,
        };
      default:
        throw new Error('Unrecognized action');
    }
  };

  const [state, dispatch] = useReducer(reducer, { swapRequests: [], count: 5, loading: false, hasMore: true });

  const { token, loading: loadingAuth } = useAuth0();

  const { swapRequests, count, loading, hasMore } = state;

  const loadMoreItems = async () => {
    try {
      dispatch({ type: 'loading' });

      const queryConfig = {
        skip: swapRequests.length,
        limit: 50,
      };

      const data = await getSwapRequests({ ...queryConfig, ...query }, token);

      dispatch({ type: 'get_deals_success', swapRequests: data.swapRequests, count: data.count });
    } catch (e) {
      console.log(e);
      dispatch({ type: 'get_deals_failure' });
    }
  };

  return { loadMoreItems, swapRequests, count, loading, loadingAuth, hasMore };
};
