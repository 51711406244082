import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useAuth0 } from 'hooks/auth-hooks';
import { Loader } from 'components/atoms';
import { LoginImmediately } from 'components/atoms';

export const AuthRoute = ({ component: Component, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return <Loader />;
        }
        if (isAuthenticated) {
          return <Component {...props} />;
        }
        return (
          <LoginImmediately
            login={() =>
              loginWithRedirect({
                redirect_uri: window.location.origin,
              })
            }
          />
        );
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
