import React, { memo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'components/molecules';
import { Typography, ThemeButton, OutlineThemeButton } from 'components/atoms';
import { Flex } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'hooks/auth-hooks';
import { useVerifyAccount } from 'hooks/useVerifyAccount';
import { requestPhoneConfirmation } from 'services/user-service';
import { handleError } from 'utils/error-handler';

const ActionButtons = styled(Flex)`
  align-items: center;
  justify-content: center;

  button {
    min-width: 11rem;
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const CheckVerificationModal = memo(({ isOpen, toggle }) => {
  const { push } = useHistory();
  const { toggleVerifyOTPModal } = useVerifyAccount();
  const { user, token } = useAuth0();
  const [modalLoading, setModalLoading] = useState(false);

  const { phoneNumber = '' } = user || {};

  const onEditProfile = () => {
    toggle();
    push('/profile/edit-number');
  };

  const requestOtp = async () => {
    try {
      setModalLoading(true);

      await requestPhoneConfirmation(token);

      setModalLoading(false);

      toggle(false);

      toggleVerifyOTPModal(true);
    } catch (e) {
      setModalLoading(false);
      handleError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph color="white">VERIFY ACCOUNT</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <ActionButtons>
          {phoneNumber ? (
            <ThemeButton onClick={requestOtp} loading={modalLoading} disabled={modalLoading} color="viridian">
              VERIFY OTP
            </ThemeButton>
          ) : (
            <ThemeButton onClick={onEditProfile} color="viridian">
              EDIT PROFILE
            </ThemeButton>
          )}
          <OutlineThemeButton loading={modalLoading} disabled={modalLoading} onClick={toggle} color="darkRed">
            CLOSE
          </OutlineThemeButton>
        </ActionButtons>
      </Modal.Body>
    </Modal>
  );
});

CheckVerificationModal.displayName = 'CheckVerificationModal';

CheckVerificationModal.propTypes = {
  dealId: PropTypes.string,
  interest: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  setDeal: PropTypes.func,
  toggle: PropTypes.func.isRequired,
};

export default CheckVerificationModal;
