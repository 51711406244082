import React from 'react';
import PropTypes from 'prop-types';

function SvgSwap({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 41 27" fill="none" {...props}>
      <path
        d="M34.489 16.758H15.613a1.63 1.63 0 000 3.258h18.876l-3.736 3.736a1.63 1.63 0 102.304 2.304l6.517-6.517c.038-.038.074-.078.108-.12.015-.018.029-.038.043-.057l.052-.07.043-.071.039-.066c.013-.024.024-.05.035-.074.011-.023.023-.046.033-.07.01-.024.018-.05.027-.074.009-.025.019-.05.027-.077l.019-.074.02-.08.013-.087c.003-.024.008-.047.01-.071.01-.107.01-.215 0-.322-.002-.024-.007-.047-.01-.071-.005-.029-.008-.058-.014-.086-.005-.028-.013-.054-.02-.08l-.018-.075c-.008-.026-.018-.052-.027-.077-.01-.025-.017-.05-.027-.073-.01-.024-.022-.047-.033-.07l-.036-.075-.038-.065c-.015-.024-.028-.048-.044-.071-.016-.025-.034-.047-.05-.07-.015-.02-.029-.04-.044-.058a1.629 1.629 0 00-.108-.12l-6.517-6.517a1.63 1.63 0 10-2.304 2.304l3.736 3.736zM12.355 10.242h3.258a1.629 1.629 0 100-3.258h-3.258a1.629 1.629 0 100 3.258z"
        fill={fill}
      />
      <path
        d="M1.32 7.58c-.012.014-.02.028-.031.041-.022.03-.044.058-.064.088-.014.02-.025.04-.037.06-.015.026-.031.05-.045.076-.012.022-.022.045-.032.067-.012.026-.025.051-.036.077-.01.023-.017.046-.025.069l-.029.081-.018.071c-.007.028-.015.055-.02.084-.006.028-.009.056-.013.084-.004.025-.008.048-.01.073-.011.107-.011.215 0 .321.002.025.006.049.01.073.004.029.007.057.013.085.005.028.013.056.02.083.006.024.011.048.018.071l.029.082.025.068c.01.027.024.052.036.077.01.023.02.045.032.068l.045.075c.012.02.023.041.037.061.02.03.042.059.064.087l.03.04c.035.044.073.085.112.124l6.514 6.515a1.63 1.63 0 102.304-2.304l-3.84-3.84a1.628 1.628 0 000-3.05l3.84-3.84A1.63 1.63 0 007.945.943L1.431 7.457c-.04.04-.077.08-.112.124zM22.128 10.242h3.258a1.629 1.629 0 100-3.258h-3.258a1.629 1.629 0 100 3.258z"
        fill={fill}
      />
    </svg>
  );
}

SvgSwap.propTypes = {
  fill: PropTypes.string,
};

SvgSwap.defaultProps = {
  fill: '#fff',
};

export default SvgSwap;
