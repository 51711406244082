import { cloudinaryClientRequest } from './http-client';
import sha1 from 'sha1';

export const uploadImage = image => {
  const timestamp = Date.now();

  const folder = 'swapurgame';

  const signatureString = `folder=${folder}&timestamp=${timestamp}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`;

  const signature = sha1(signatureString);

  return cloudinaryClientRequest
    .post('/image/upload', {
      file: image,
      api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
      timestamp,
      signature,
      folder,
    })
    .then(res => res.data);
};
