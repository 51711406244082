import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid, Logo, Typography } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import { FacebookIcon } from 'components/icons';
import { TwitterIcon } from 'components/icons';
import { InstagramIcon } from 'components/icons';

const Container = styled(Box)`
  display: flex;
  padding: 10rem 0;
`;

const ListItem = styled(Link)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors['offWhite6']};
  }

  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.font.size.small};
  text-decoration: none;

  svg {
    opacity: 0.6;
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

ListItem.defaultProp = {
  as: Typography.Paragraph,
  to: '#',
};

export const Footer = () => (
  <Container>
    <Grid.Container>
      <Grid.Row>
        <Grid.Col sm={6} lg={3} mb="3rem">
          <Logo style={{ marginBottom: '1rem' }} />
          <Typography.Paragraph color="offWhite6">
            Copyrights © {new Date().getFullYear()} SUG. All rights reserved.
          </Typography.Paragraph>
        </Grid.Col>

        <Grid.Col sm={6} lg={3} mb="3rem">
          <Typography.Heading type="h4" color="white" bold style={{ marginBottom: '2rem' }}>
            COMMUNITY
          </Typography.Heading>

          <ListItem className="link link__default">
            <FacebookIcon />
            Like us on facebook
          </ListItem>

          <ListItem
            className="link link__default"
            as="a"
            href="https://twitter.com/swapurgame"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
            Follow us on Twitter
          </ListItem>

          <ListItem className="link link__default">
            <InstagramIcon />
            Follow us on Instagram
          </ListItem>
        </Grid.Col>

        <Grid.Col sm={6} lg={3} mb="3rem">
          <Typography.Heading type="h4" color="white" bold style={{ marginBottom: '2rem' }}>
            SITE
          </Typography.Heading>
          <ListItem className="link link__default" to="/how-it-works">
            How it works
          </ListItem>
          <ListItem className="link link__default" to="/deals">
            Deals
          </ListItem>
          <ListItem className="link link__default">Help Center</ListItem>
        </Grid.Col>

        <Grid.Col sm={6} lg={3} mb="3rem">
          <Typography.Heading type="h4" color="white" bold style={{ marginBottom: '2rem' }}>
            LEGAL
          </Typography.Heading>
          <ListItem className="link link__default" to="/terms-of-use">
            Terms of Use
          </ListItem>
          <ListItem className="link link__default" to="/privacy-policy">
            Privacy Policy
          </ListItem>
          <ListItem className="link link__default">Listing Rules</ListItem>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  </Container>
);
