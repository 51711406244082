import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CheckVerificationModal from 'components/organisms/CheckVerificationModal';
import VerifyOTPModal from 'components/organisms/VerifyOTPModal';
import { useToggle } from 'react-use';

export const VerifyAccountContext = React.createContext({});

export const VerifyAccountProvider = ({ children }) => {
  const [checkVerificationModal, toggleCheckVerificationModal] = useToggle(false);
  const [verifyOTPModal, toggleVerifyOTPModal] = useToggle(false);

  const value = useMemo(
    () => ({
      toggleCheckVerificationModal,
      toggleVerifyOTPModal,
    }),
    [toggleCheckVerificationModal, toggleVerifyOTPModal],
  );

  return (
    <VerifyAccountContext.Provider value={value}>
      {children}
      <CheckVerificationModal isOpen={checkVerificationModal} toggle={toggleCheckVerificationModal} />
      <VerifyOTPModal isOpen={verifyOTPModal} toggle={toggleVerifyOTPModal} />
    </VerifyAccountContext.Provider>
  );
};

VerifyAccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
