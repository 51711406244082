import React from 'react';
import styled, { css } from 'styled-components';
import { OutlineThemeButton as OutlineButton } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const Content = styled.div`
  background: ${({ theme }) => theme.colors.dark};
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  margin: 6rem 1rem 6rem;
  position: relative;
  transition: transform 0.3s ease-out;
  width: 100%;
  z-index: 10;

  ${({ showCloseIcon }) =>
    showCloseIcon &&
    css`
      padding-top: 4rem;
    `}

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-top: 10rem;
  }

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        max-width: 36rem;
      `;
    }
    if (size === 'md') {
      return css`
        max-width: 50rem;
      `;
    }
    if (size === 'lg') {
      return css`
        max-width: 72rem;
      `;
    }
  }}
`;

const Overlay = styled(Box)`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: flex;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s;
`;

const Wrapper = styled(Box)`
  align-items: flex-start;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: scroll;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s;
  visibility: hidden;
  z-index: 9;

  ${Content} {
    transform: translate(0, -50px);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;

      ${Content} {
        transform: translate(0, 0);
      }

      ${Overlay} {
        opacity: 1;
      }
    `}
`;

const ModalHeader = styled(Box)`
  align-items: center;
  display: flex;
  padding: 1.5rem 2rem;
  padding-bottom: 0;
  position: relative;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 2rem 3.5rem;
    padding-bottom: 0;
  }
`;

const ModalBody = styled.div`
  padding: 2rem 2rem;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 2.5rem 3.5rem;
  }
`;

const ModalFooter = styled.div`
  padding: 1.5rem 2rem;
  position: relative;
`;

const CloseButton = styled(OutlineButton)`
  align-items: center;
  border-width: 0.1rem;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 3.5rem;
  justify-content: center;
  padding-right: 1px;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 3.5rem;

  &:before,
  &:after {
    background: ${({ theme: { colors } }) => colors.white};
    border-radius: 2px;
    content: '';
    display: block;
    height: 0.1rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transition: 0.3s all;
    width: 2rem;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const CloseIcon = props => <CloseButton color="white" {...props} />;

const Modal = ({ close, children, size, isOpen, toggle, showCloseIcon, ...rest }) => {
  return (
    <Wrapper isOpen={isOpen} {...rest}>
      <Overlay onClick={toggle} />
      <Content size={size} showCloseIcon={showCloseIcon}>
        {showCloseIcon && <CloseIcon onClick={toggle} />}
        {children}
      </Content>
    </Wrapper>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

Modal.defaultProps = {
  close: () => {},
  showCloseIcon: false,
  size: 'sm',
};

Modal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  showCloseIcon: PropTypes.bool,
  size: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

export { Modal };
