import { authRequest } from './http-client';

export const updateUser = (userData, { token, userId }) => {
  return authRequest(token)
    .patch(`/user/update/${userId}`, userData)
    .then(res => res.data);
};

export const updateUserEmail = (userData, { token }) => {
  return authRequest(token)
    .patch(`/user/change-email/`, userData)
    .then(res => res.data);
};

export const updatePhoneNumber = (userData, { token }) => {
  return authRequest(token)
    .patch(`/user/change-phone/`, userData)
    .then(res => res.data);
};

export const requestPhoneConfirmation = token => {
  return authRequest(token)
    .get(`/user/request-phone-confirmation`)
    .then(res => res.data);
};

export const confirmPhoneNumber = (userData, token) => {
  return authRequest(token)
    .post(`/user/confirm-phone`, userData)
    .then(res => res.data);
};
