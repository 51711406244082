import React from 'react';
import { Typography } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const IconBox = ({ icon, subtitle, title }) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <Box display="flex" mb="3rem">
      {icon}
    </Box>

    <Typography.Heading fontWeight="bold" type="h4" color="white" textAlign="center" style={{ marginBottom: '1.4rem' }}>
      {title}
    </Typography.Heading>

    <Typography.Paragraph type="h4" color="offWhite8" textAlign="center">
      {subtitle}
    </Typography.Paragraph>
  </Box>
);

IconBox.propTypes = {
  icon: PropTypes.node.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
