import { toast } from 'react-toastify';

export function handleError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 401) {
      if (
        error.response.config &&
        error.response.config.data &&
        JSON.parse(error.response.config.data).username &&
        JSON.parse(error.response.config.data).password
      ) {
        return toast.error('The username or password is incorrect, please retry');
      }
      return toast.error('You are not authorized to carry out this request');
    } else if (error.response.data && error.response.data.name === 'ValidationError') {
      const errors = error.response.data.details;
      const message = errors[0].message;
      toast.error(message, { autoClose: 20000 });
    } else if (error.response.status === 460 && error.response.data) {
      toast.error(error.response.data.message);
    } else if (error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      console.log(error.response);
      toast.error('oops! something went wrong, please retry your request');
    }
  } else if (error.request) {
    toast.error('Could not process request');
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error('Could not complete request, check data and retry');
  }
}
