import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LogoText = styled.h3`
  color: ${props => props.theme.colors.white};
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.06rem;
  line-height: 3rem;
  margin-bottom: 0;
  user-select: none;

  span {
    color: ${props => props.theme.colors.red};
  }
`;

export const Logo = ({ style }) => (
  <LogoText style={style}>
    swap<span>ur</span>game
  </LogoText>
);

Logo.defaultProps = {
  style: {},
};

Logo.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
};
