import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'components/molecules';
import { Typography, ThemeButton } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import { Link } from 'react-router-dom';
import { OutlineThemeButton } from 'components/atoms';
import { useAuth0 } from 'hooks/auth-hooks';

const Prompt = styled(Box)`
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Buttons = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  button,
  a {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const AuthModal = memo(() => {
  const { modalOpen: isOpen, toggleModal: toggle } = useAuth0();

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph color="white">Log in to continue</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <Prompt>
          <Typography.Paragraph color="offWhite6" textAlign="center" fontSize="small">
            You need to be logged in to perform this action.
          </Typography.Paragraph>
        </Prompt>

        <Buttons>
          <OutlineThemeButton cocolor="white" as={Link} to="/login" onClick={toggle}>
            LOGIN
          </OutlineThemeButton>
          <ThemeButton as={Link} to="/signup" onClick={toggle}>
            SIGNUP
          </ThemeButton>
        </Buttons>
      </Modal.Body>
    </Modal>
  );
});

AuthModal.displayName = 'AuthModal';

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AuthModal;
