import React from 'react';
import PropTypes from 'prop-types';

function SvgShare({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 58 58" fill="none" {...props}>
      <path
        d="M54.32 37.839c.442-1.921.68-3.879.68-5.839 0-9.095-4.63-17.377-12.389-22.153a1 1 0 10-1.049 1.703C48.724 15.96 53 23.604 53 32c0 1.726-.2 3.451-.573 5.147A6.992 6.992 0 0051 37c-3.86 0-7 3.141-7 7s3.14 7 7 7 7-3.141 7-7a7.006 7.006 0 00-3.68-6.161zM51 49c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zM38.171 54.182A23.867 23.867 0 0129 56a24.047 24.047 0 01-17.017-7.092A6.974 6.974 0 0014 44c0-3.859-3.14-7-7-7s-7 3.141-7 7 3.14 7 7 7a6.952 6.952 0 003.381-.875C15.26 55.136 21.994 58 29 58c3.435 0 6.778-.663 9.936-1.971.51-.211.753-.796.542-1.307a1.001 1.001 0 00-1.307-.54zM2 44c0-2.757 2.243-5 5-5s5 2.243 5 5-2.243 5-5 5-5-2.243-5-5zM4 31.213a1 1 0 001.068-.927c.712-10.089 7.586-18.52 17.22-21.314C23.142 11.874 25.825 14 29 14c3.86 0 7-3.141 7-7s-3.14-7-7-7c-3.851 0-6.985 3.127-7 6.975C11.42 9.922 3.85 19.12 3.074 30.146A.999.999 0 004 31.213zM29 2c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5z"
        fill={fill}
      />
    </svg>
  );
}

SvgShare.propTypes = {
  fill: PropTypes.string,
};

SvgShare.defaultProps = {
  fill: '#fff',
};

export default SvgShare;
