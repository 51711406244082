import React, { useContext, useState, forwardRef } from 'react';
import { Typography } from 'components/atoms';
import { useHistory } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import { useAuth0 } from 'hooks/auth-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faHeart,
  faEye,
  faTrash,
  faHandshake,
  faCalendar,
  faMapMarker,
  faTimes,
  faGamepad,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { ActionBox, CardWrapper, DetailBox, ActionOutlineButton, ActionButton, IconBox } from './styled';
import { Loader } from './Loader';
import { Poster } from './Poster';
import { DeleteDealContext } from 'contexts/modals/DeleteDealContext';
import { ShareDealContext } from 'contexts/modals/ShareDealContext';
import { ImageSelector } from './ImageSelector';
import { consolesMap as consoles } from 'utils/constants';

export const DealCard = forwardRef(
  (
    {
      backgroundColor,
      deal = {},
      hideViewDetails,
      loading,
      noActions,
      onInterestedClick,
      style,
      type,
      withImageSelector,
      ...props
    },
    ref,
  ) => {
    const {
      _id,
      user,
      images,
      createdAt,
      offering,
      wants,
      swapInterests,
      acceptedInterest,
      status,
      note,
      category,
    } = deal;
    const { toggleModal: startDelete } = useContext(DeleteDealContext);

    const { toggleModal: startShare } = useContext(ShareDealContext);

    const { push } = useHistory();
    const [showDescription, setShowDescription] = useState(false);
    const [activeImage, setActiveImage] = useState(() => {
      return images ? images[0] : null;
    });
    const toggleDescription = () => setShowDescription(v => !v);
    const { user: authUser, toggleModal: startAuth } = useAuth0();

    const InterestButton = () => {
      if (!authUser) {
        return (
          <ActionButton className="action-btn" color="red" onClick={startAuth}>
            <FontAwesomeIcon icon={faHeart} color="#fff" /> Interested
          </ActionButton>
        );
      }

      if (authUser.email === user?.email) {
        return null;
      }

      if (swapInterests.some(interest => interest.user.email === authUser.email)) {
        return (
          <ActionButton className="action-btn" disabled onClick={() => {}} color="red">
            <FontAwesomeIcon icon={faCheck} color="#fff" />
          </ActionButton>
        );
      }

      return (
        <ActionButton className="action-btn" onClick={() => onInterestedClick(_id)} color="red">
          <FontAwesomeIcon icon={faHeart} color="#fff" /> Interested
        </ActionButton>
      );
    };

    const StatusLabel = () => {
      if (type === 'interest') {
        return null;
      }
      if (status === 'active') {
        return <InterestButton />;
      }

      if (status === 'closed' && acceptedInterest) {
        return (
          <ActionButton className="action-btn" color="viridian">
            <FontAwesomeIcon icon={faHandshake} /> PAIRED
          </ActionButton>
        );
      }

      return (
        <ActionButton className="action-btn" color="red">
          <FontAwesomeIcon icon={faTimes} /> CLOSED
        </ActionButton>
      );
    };

    return (
      <CardWrapper
        backgroundColor={backgroundColor}
        justifyContent="space-between"
        loading={loading}
        ref={ref}
        style={style}
        {...props}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <DetailBox>
              {withImageSelector && (
                <ImageSelector activeImage={activeImage} onSelect={img => setActiveImage(img)} images={images} />
              )}
              <Poster src={activeImage} alt="" />
              <Box display="flex" flexDirection="column">
                <Typography.Heading color="white" type="h4" style={{ marginBottom: '2rem' }}>
                  {`${offering}${wants ? ` for ${wants}` : ''}`}
                </Typography.Heading>

                <IconBox>
                  <Typography.Text fontSize="small" color="offWhite6" style={{ marginRight: '2rem' }}>
                    <FontAwesomeIcon icon={faCalendar} />{' '}
                    {createdAt && formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
                  </Typography.Text>

                  {user.location && (
                    <Typography.Text fontSize="small" color="offWhite6">
                      <FontAwesomeIcon icon={faMapMarker} /> {`${user.location.city}, ${user.location.state}`}
                    </Typography.Text>
                  )}
                </IconBox>

                {swapInterests?.length ? (
                  <IconBox>
                    <Typography.Text color="offWhite6">
                      <FontAwesomeIcon icon={faEye} /> {swapInterests.length} Interests
                    </Typography.Text>
                  </IconBox>
                ) : null}

                {category ? (
                  <IconBox>
                    <Typography.Text color="offWhite6">
                      <FontAwesomeIcon icon={faGamepad} /> {consoles[category]}
                    </Typography.Text>
                  </IconBox>
                ) : null}

                {note && (
                  <IconBox>
                    <Typography.Text color="offWhite6">
                      <FontAwesomeIcon icon={faEye} />
                      {showDescription && note}{' '}
                      {!showDescription && (
                        <Typography.Text decoration="underline" onClick={toggleDescription} size="tiny">
                          {showDescription ? 'Hide' : 'View'} description
                        </Typography.Text>
                      )}
                    </Typography.Text>
                  </IconBox>
                )}
              </Box>
            </DetailBox>

            <ActionBox flexDirection="column" justifyContent="space-between">
              {!hideViewDetails && (
                <ActionOutlineButton
                  className="action-btn"
                  color="white"
                  onClick={() => push(`/deals/${_id}`, { deal })}
                >
                  <FontAwesomeIcon icon={faEye} /> See Details
                </ActionOutlineButton>
              )}

              <ActionOutlineButton className="action-btn" color="red" onClick={() => startShare(_id)}>
                <FontAwesomeIcon icon={faShare} /> Share
              </ActionOutlineButton>

              {noActions ? null : <StatusLabel />}

              {status === 'active' && authUser?.email === user.email && !swapInterests.length && (
                <ActionOutlineButton className="action-btn" color="primary" onClick={() => startDelete(_id)}>
                  <FontAwesomeIcon icon={faTrash} /> Delete
                </ActionOutlineButton>
              )}
            </ActionBox>
          </>
        )}
      </CardWrapper>
    );
  },
);

DealCard.displayName = 'Deal';

DealCard.propTypes = {
  backgroundColor: PropTypes.string,
  deal: PropTypes.object,
  hideViewDetails: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  noActions: PropTypes.bool,
  onInterestedClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  time: PropTypes.string,
  type: PropTypes.string,
  withImageSelector: PropTypes.bool,
};

DealCard.defaultProps = {
  withImageSelector: false,
};
