import axios from 'axios';
import { AUTH0_DOMAIN_URL } from 'utils/constants';
import { CLOUDINARY_URL } from 'utils/constants';

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

export const authRequest = token =>
  axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

export const auth0ClientRequest = axios.create({
  baseURL: AUTH0_DOMAIN_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const cloudinaryClientRequest = axios.create({
  baseURL: CLOUDINARY_URL,
});
