import React from 'react';
import PropTypes from 'prop-types';

function SvgSmile({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" fill="none" {...props}>
      <g clipPath="url(#smile_svg__clip0)" fill={fill}>
        <path d="M12.653 34.132a1.18 1.18 0 00-.829-.344c-.308 0-.61.126-.829.344a1.18 1.18 0 00-.343.829c0 .31.125.61.343.83a1.18 1.18 0 001.658 0c.218-.22.344-.52.344-.83 0-.309-.126-.611-.344-.83zM23.71 34.132a1.18 1.18 0 00-.83-.344c-.308 0-.61.126-.828.344a1.18 1.18 0 00-.344.829c0 .31.125.61.344.83.218.218.52.342.828.342.309 0 .611-.124.83-.342.217-.22.343-.522.343-.83 0-.309-.126-.611-.344-.83zM21.559 40.57a1.172 1.172 0 00-1.436.83 2.873 2.873 0 01-2.77 2.13 2.873 2.873 0 01-2.772-2.13 1.172 1.172 0 10-2.265.605 5.221 5.221 0 005.036 3.87 5.221 5.221 0 005.037-3.87 1.173 1.173 0 00-.83-1.435zM30.014 4.687c-.647 0-1.172.525-1.172 1.172v7.443a1.172 1.172 0 102.345 0V5.86c0-.647-.525-1.172-1.173-1.172zM30.843 16.617a1.177 1.177 0 00-1.658 0 1.18 1.18 0 00-.343.828c0 .309.125.611.343.83.219.217.52.343.83.343.308 0 .61-.126.828-.344.218-.218.344-.52.344-.829 0-.308-.126-.61-.344-.828zM22.87 15.346l-2.994-2.99a1.172 1.172 0 10-1.657 1.658l2.993 2.99a1.169 1.169 0 001.658 0c.458-.458.457-1.2 0-1.658zM41.813 12.358a1.172 1.172 0 00-1.658 0l-3.183 3.185a1.172 1.172 0 001.659 1.658l3.182-3.185c.458-.458.458-1.2 0-1.658z" />
        <path d="M59.407 33.462c-2.477-9.243-12.01-14.747-21.252-12.27a17.238 17.238 0 00-8.163 4.88 17.263 17.263 0 00-12.64-5.463C7.784 20.609 0 28.393 0 37.96c0 9.568 7.784 17.352 17.352 17.352 4.837 0 9.377-1.982 12.653-5.478 3.244 3.44 7.818 5.472 12.645 5.472 1.482 0 2.989-.192 4.487-.593 9.242-2.477 14.746-12.01 12.27-21.252zM17.352 52.968c-8.275 0-15.007-6.732-15.007-15.007 0-8.275 6.732-15.008 15.007-15.008 4.284 0 8.296 1.796 11.143 4.955-.31.44-.604.896-.877 1.369a17.239 17.239 0 00-1.733 13.167 17.268 17.268 0 002.617 5.563 14.938 14.938 0 01-11.15 4.961zm29.178-.52c-7.993 2.142-16.238-2.618-18.38-10.61a14.91 14.91 0 011.499-11.389 15.226 15.226 0 011.287-1.886 14.896 14.896 0 017.825-5.106 14.98 14.98 0 013.881-.513c6.626 0 12.705 4.428 14.5 11.125 2.141 7.993-2.619 16.238-10.612 18.38z" />
        <path d="M39.212 35.654a2.545 2.545 0 00-3.115-1.798 2.546 2.546 0 00-1.798 3.114 1.173 1.173 0 102.265-.606.199.199 0 01.383-.103 1.172 1.172 0 102.264-.607zM49.825 32.81a2.527 2.527 0 00-1.185-1.544 2.526 2.526 0 00-1.93-.254 2.526 2.526 0 00-1.544 1.185 2.526 2.526 0 00-.254 1.93 1.173 1.173 0 002.265-.607.195.195 0 01.02-.15.194.194 0 01.12-.093.195.195 0 01.15.02.194.194 0 01.093.12 1.172 1.172 0 102.265-.607zM48.797 40.167a1.173 1.173 0 00-2.265.607 2.799 2.799 0 01-1.976 3.424 2.777 2.777 0 01-2.122-.28 2.777 2.777 0 01-1.302-1.697 1.172 1.172 0 10-2.265.607 5.107 5.107 0 002.395 3.121 5.11 5.11 0 003.9.514 5.146 5.146 0 003.635-6.296z" />
      </g>
      <defs>
        <clipPath id="smile_svg__clip0">
          <path fill={fill} d="M0 0h60v60H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

SvgSmile.propTypes = {
  fill: PropTypes.string,
};

SvgSmile.defaultProps = {
  fill: '#fff',
};

export default SvgSmile;
