import React from 'react';
import PropTypes from 'prop-types';

function SvgCheck({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M13 0C5.832 0 0 5.832 0 13c0 7.169 5.832 13 13 13s13-5.831 13-13c0-7.168-5.832-13-13-13zm0 25C6.383 25 1 19.616 1 13 1 6.383 6.383 1 13 1c6.616 0 12 5.383 12 12 0 6.616-5.384 12-12 12z"
        fill={fill}
      />
      <path
        d="M19.126 7.668l-7.685 8.645-4.63-3.703a.5.5 0 00-.624.78l5 4a.5.5 0 00.687-.058l8-9a.5.5 0 10-.748-.664z"
        fill={fill}
      />
    </svg>
  );
}

SvgCheck.propTypes = {
  fill: PropTypes.string,
};

SvgCheck.defaultProps = {
  fill: '#518EF8',
};

export default SvgCheck;
