import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader } from './Loader';

export const LoginImmediately = ({ login }) => {
  useEffect(() => {
    login();
  }, [login]);

  return <Loader />;
};

LoginImmediately.propTypes = {
  login: PropTypes.func.isRequired,
};
