import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

if (process.env.REACT_APP_STAGE === 'live') {
  ReactGA.initialize('UA-176817440-1');
}

const AppConfig = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'live') {
      ReactGA.pageview(pathname + search);
    }
  }, [pathname, search]);

  return <></>;
};

export default AppConfig;
