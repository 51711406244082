import React, { useState } from 'react';
import { Typography } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faCalendar, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { ActionBox, CardWrapper, DetailBox, ActionButton, IconBox } from './styled';
import { Poster } from './Poster';
import { Loader } from './Loader';
import { ImageSelector } from './ImageSelector';

export const PairCard = ({ backgroundColor, interest, onAcceptClick, loading, style, withImageSelector, ...props }) => {
  const { createdAt, images, user, offering } = interest;
  const [activeImage, setActiveImage] = useState(() => {
    return images ? images[0] : null;
  });

  return (
    <CardWrapper
      backgroundColor={backgroundColor}
      justifyContent="space-between"
      style={style}
      loading={loading}
      {...props}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <DetailBox>
            {withImageSelector && (
              <ImageSelector activeImage={activeImage} onSelect={img => setActiveImage(img)} images={images} />
            )}
            <Poster src={activeImage} />
            <Box flexDirection="column" display="flex">
              <Typography.Heading color="white" type="h4" style={{ marginBottom: '2rem' }}>
                {offering}
              </Typography.Heading>

              <IconBox mb="1rem">
                <Typography.Text fontSize="small" color="offWhite6" style={{ marginRight: '2rem' }}>
                  <FontAwesomeIcon icon={faCalendar} />{' '}
                  {createdAt && formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
                </Typography.Text>

                {user?.location && (
                  <Typography.Text fontSize="small" color="offWhite6">
                    <FontAwesomeIcon icon={faMapMarker} /> {`${user.location.city}, ${user.location.state}`}
                  </Typography.Text>
                )}
              </IconBox>
            </Box>
          </DetailBox>
          <ActionBox flexDirection="column" display="flex" justifyContent="space-between">
            <ActionButton className="action-btn" color="viridian" onClick={() => onAcceptClick(interest)}>
              <FontAwesomeIcon icon={faHandshake} /> ACCEPT
            </ActionButton>
          </ActionBox>
        </>
      )}
    </CardWrapper>
  );
};

PairCard.defaultProps = {
  canSeeDetails: true,
  location: '',
  style: {},
  swapInterests: [],
  withImageSelector: false,
};

PairCard.propTypes = {
  backgroundColor: PropTypes.string,
  dealId: PropTypes.string,
  interest: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onAcceptClick: PropTypes.func.isRequired,
  setDeal: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  withImageSelector: PropTypes.bool,
};
