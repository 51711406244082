import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OutlineThemeButton, Typography } from 'components/atoms';
import { Flex } from 'rebass/styled-components';

const BannerWrapper = styled(Flex)`
    background-color:#550b0f;
    margin:0 auto;
    padding:2rem 1rem;
    flex-direction:column;
    color:#fff;
    .left {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 2rem;
        max-width: 55rem;
        .title, .subtitle {
            color: #fff;
        }
        .title {
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 0.69px;
            color: #ffffff;
            margin-bottom: 1rem;
        }
        .subtitle {
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.44;
            letter-spacing: 0.44px;
            color: #ffffff;
        }
    }
    .right {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    ${({ theme }) => theme.mq.sm`
    .left{
        .title{
            font-size: 2.5rem;
        }
        .subtitle{
            font-size: 1.6rem;
        }
        }
    `}

    ${({ theme }) => theme.mq.md`
    padding: 3rem 2rem;
    width: 85%;
    `}

    ${({ theme }) => theme.mq.lg`
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem 4rem;
        .left{
            margin-bottom: 0;
        }
    `}
`;

export const Banner = ({ buttonAction, buttonText, subtitle, title }) => {
  return (
    <BannerWrapper>
      <div className="left">
        <Typography.Paragraph className="title">{title}</Typography.Paragraph>
        <Typography.Paragraph className="subtitle">{subtitle}</Typography.Paragraph>
      </div>

      <div className="right">
        <OutlineThemeButton color="white" onClick={buttonAction}>
          {buttonText}
        </OutlineThemeButton>
      </div>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  buttonAction: PropTypes.func,
  buttonText: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

Banner.defaultProps = {
  title: `Can’t find a perfect deal?`,
  subtitle: `  Join the community by logging into your account or by signing up for new membership. Join the community by
    logging into your account or by signing up for new membership.`,
  buttonText: `GET STARTED`,
  buttonAction: () => {},
};
