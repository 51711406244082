import React, { lazy, Suspense, useEffect, memo } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from './history';
import PageWrapper from 'components/organisms/PageWrapper';
import { Loader } from 'components/atoms';
import { NonAuthRoute } from 'ProtectedRoutes/NonAuthRoutes';
import { AuthRoute } from 'ProtectedRoutes/AuthRoutes';
import { VerifyAccountProvider } from 'contexts/VerifyAccountContext';
import AppConfigs from './AppConfigs';
import AuthModal from 'components/organisms/AuthModal';

const DealsWrapper = lazy(() => import('pages/Deals'));
const Home = lazy(() => import('pages/Home'));
const Register = lazy(() => import('pages/Register'));
const Login = lazy(() => import('pages/LoginAuth0'));
const Profile = lazy(() => import('pages/Profile'));
const HowItWorks = lazy(() => import('pages/HowItWorks'));
const PrivacyPolicy = lazy(() => import('pages/Static/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('pages/Static/TermsOfUse'));

const Scroll = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return <PageWrapper>{props.children}</PageWrapper>;
};

Scroll.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const ScrollToTop = withRouter(memo(Scroll));

const RouterComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <AppConfigs />
        <VerifyAccountProvider>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <NonAuthRoute path="/signup" component={Register} />
              <NonAuthRoute path="/register" component={Register} />
              <NonAuthRoute path="/login" component={Login} />
              <Route path="/deals" component={DealsWrapper} />
              <AuthRoute path="/profile" component={Profile} />
              <Route path="/how-it-works" component={HowItWorks} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms-of-use" component={TermsOfUse} />
            </Switch>
          </ScrollToTop>

          <AuthModal />
        </VerifyAccountProvider>
      </Router>
    </Suspense>
  );
};

export default RouterComponent;
