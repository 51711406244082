import React from 'react';

function SvgError(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 189 127" fill="#fff" {...props}>
      <path d="M162.389 50.774c-1.006-19.992-10.226-40.447-30.189-47.878-22.094-8.224-42.14 2.029-56.215 18.663-11.053-9.56-26.17-9.187-38.105.943-10.918 9.266-20.823 25.972-10.16 38.875-22.65.717-37.898 34.532-19.586 50.877 25.109 22.412 65.019 11.639 95.409 13.074 31.702 1.496 70.72 7.449 82.608-30.127 6.444-20.368-1.825-45.21-23.762-44.427zM54.381 63.57a23.231 23.231 0 00-.634-2.067A390 390 0 0058.614 66a73.013 73.013 0 00-4.233-2.43zm43.722 32.71c-.156.112-.308.229-.465.34.646-.872 1.28-1.735 1.894-2.558.571.525 1.116 1.075 1.682 1.608-1.11-.137-2.296.035-3.111.61zm25.177 9.362c-6.889-7.242-13.591-14.641-21.196-21.158-1.67-1.43-4.647-1.974-6.238 0-5.696 7.066-10.121 16.026-17.508 21.453-4.591-2.186-9.182-4.371-13.772-6.558a24.5 24.5 0 016.187-9.137c3.07-3.417 6.757-6.34 10.264-9.288 1.776-1.493 1.57-4.545 0-6.073-6.486-6.31-13.265-12.298-19.803-18.55 3.645-4.826 8.158-8.307 13.545-11.42 2.476 8.127 16.248 17.508 21.284 21.4 1.75 1.354 4.141 1.778 5.846 0 8.346-8.702 16.049-19.551 21.996-30.146 2.97 4.388 5.998 8.879 7.751 13.806.007.042.017.08.024.122 1.945 6.092-13.034 17.869-16.424 21.495-1.58 1.69-1.797 4.538 0 6.2 6.447 5.96 13.478 11.123 20.453 16.412-4.284 3.659-8.379 7.501-12.409 11.442zm49.035-4.371a26.037 26.037 0 01-7.878 9.008c3.648-3.631 6.811-7.67 9.233-11.91a32.372 32.372 0 01-1.355 2.902z" />
    </svg>
  );
}

export default SvgError;
