import styled, { css } from 'styled-components';
import { Image, OutlineThemeButton, ThemeButton } from 'components/atoms';
import { Box, Flex } from 'rebass/styled-components';
import { Typography } from 'components/atoms';

export const CardWrapper = styled(Box)`
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 3rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.theme.colors.grey};
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
`;

export const Poster = styled(Image)`
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
  flex-shrink: 0;
  height: ${({ height }) => height}px;
  object-fit: cover;
  width: 100%;
`;

export const DetailBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mq.sm`
  align-items: center;
  flex-direction: row;
  margin-bottom: 0;
    `}
`;

export const ActionBox = styled(Box)`
  display: flex;
  .action-btn + .action-btn {
    margin-top: 1rem;
  }
`;

export const ActionButton = styled(ThemeButton)`
  svg {
    margin-right: 0.5rem;
    width: 1.2rem !important;
  }
`;

export const ActionOutlineButton = styled(OutlineThemeButton)`
  svg {
    margin-right: 0.5rem;
    width: 1.2rem !important;
  }
`;

export const IconBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;

  ${Typography.Text} {
    margin-bottom: 1rem;
  }

  svg {
    margin-right: 1rem;
    width: 1rem !important;
  }
`;

export const SelectorImage = styled(Image)`
  cursor: pointer;
  flex-shrink: 0;
  height: 30px;
  object-fit: cover;
  width: 37px;

  ${({ active, theme }) =>
    active &&
    css`
      border: solid 1px ${theme.colors.red};
      box-shadow: 0 4px 15px 0 rgba(175, 23, 31, 0.45);
    `}
`;

export const SelectorWrapper = styled(Flex)`
  margin-bottom: 1rem;

  ${SelectorImage} {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  ${({ theme }) => theme.mq.sm`
    align-self: flex-start;
    flex-direction: column;
    margin-bottom: 0;
    margin-right: 0.7rem;


    ${SelectorImage}{
      &:not(:last-child){
        margin-bottom: 0.5rem;s
        margin-right: 0;
      }
    }
`}
`;
