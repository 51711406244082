import styled from 'styled-components';

export const Checkbox = styled.div`
  align-items: center;
  border-radius: 2px;
  border: 0.2rem solid ${props => props.theme.colors.offWhite8};
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-right: 1rem;
  position: relative;
  width: 2rem;

  &::before {
    background-color: ${props => props.theme.colors.offWhite8};
    border-radius: 1px;
    content: '';
    display: block;
    height: 2rem;
    left: 50%;
    opacity: ${props => (props.isChecked ? 1 : 0)};
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
    width: 2rem;
  }

  &::after {
    border-bottom: 3px solid #111111;
    border-radius: 2px;
    border-right: 3px solid #111111;
    content: '';
    display: inline-block;
    height: 14px;
    left: 2px;
    opacity: ${props => (props.isChecked ? 1 : 0)};
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    transition: opacity 0.2s;
    width: 8px;
  }
`;
