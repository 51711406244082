import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import createAuth0Client from '@auth0/auth0-spa-js';
import { getAuthUser } from 'services/auth-service';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  domain,
  client_id,
  redirect_uri,
  audience,
  scope,
}) => {
  const authOptions = useMemo(() => ({ domain, client_id, redirect_uri, audience, scope }), [
    domain,
    client_id,
    redirect_uri,
    audience,
    scope,
  ]);

  const [isAuthenticated, setIsAuthenticated] = useState();

  const [token, setToken] = useState(null);

  const [user, setUser] = useState(null);

  const [auth0User, setAuth0User] = useState(null);

  const [auth0Client, setAuth0] = useState({});

  const [loading, setLoading] = useState(true);

  const [popupOpen, setPopupOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(v => !v);

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client(authOptions);

        setAuth0(auth0FromHook);

        if (window.location.search.includes('code=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const token = await auth0FromHook.getTokenSilently();

        setToken(token);

        const isAuthenticated = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          setAuth0User(await auth0FromHook.getUser());

          const user = await getAuthUser(auth0FromHook);

          setUser(user);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    initAuth0();
  }, [authOptions, onRedirectCallback]);

  const handleRedirectCallback = async () => {
    setLoading(true);

    await auth0Client.handleRedirectCallback();

    const user = await getAuthUser(auth0Client);

    setUser(user);

    setIsAuthenticated(true);

    setLoading(false);
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);

    try {
      await auth0Client.loginWithPopup(params);

      setAuth0User(await auth0Client.getUser());

      const user = await getAuthUser(auth0Client);

      setUser(user);

      setIsAuthenticated(true);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        auth0User,
        getIdTokenClaims: auth0Client.getIdTokenClaims,
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        handleRedirectCallback,
        isAuthenticated,
        loading,
        loginWithPopup,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => auth0Client.logout(...p),
        modalOpen,
        popupOpen,
        setUser,
        toggleModal,
        token,
        user,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onRedirectCallback: PropTypes.func,
  domain: PropTypes.string,
  redirect_uri: PropTypes.string,
  client_id: PropTypes.string,
  audience: PropTypes.string,
  scope: PropTypes.string,
};
