import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from 'hooks/auth-hooks';
import { Loader } from 'components/atoms';

export const NonAuthRoute = ({ component: Component, ...rest }) => {
  const { loading, isAuthenticated } = useAuth0();

  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return <Loader />;
        }
        if (isAuthenticated) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

NonAuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
