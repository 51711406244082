import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteDealModal from 'components/organisms/DeleteDealModal';

export const DeleteDealContext = React.createContext({});

export const DeleteDealProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');

  const toggleModal = (dealId = '') => {
    if (isOpen) {
      setIsOpen(false);
      setId('');
    } else {
      setIsOpen(true);
      setId(dealId);
    }
  };

  return (
    <DeleteDealContext.Provider
      value={{
        isOpen,
        id,
        toggleModal,
      }}
    >
      {children}
      <DeleteDealModal isOpen={isOpen} toggle={toggleModal} dealId={id} set={setIsOpen} />
    </DeleteDealContext.Provider>
  );
};

DeleteDealProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
