import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { ErrorDiv } from './ErrorDiv';

export const Textarea = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: transparent;
  border-radius: 0;
  border: 2px solid ${props => props.theme.colors.offWhite6};
  color: ${props => props.theme.colors.offWhite6};
  display: block;
  font-size: 16px;

  margin: 0;
  outline: none;
  padding: 1.5rem;
  transition: all 0.2s;
  width: 100%;

  &:focus {
    border: 2px solid ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.white};
  }
`;

export const FormTextArea = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Textarea {...field} {...props} />
      {meta.touched && meta.error ? <ErrorDiv>{meta.error}</ErrorDiv> : null}
    </>
  );
};
