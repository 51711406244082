import React from 'react';
import Router from 'Router';
import styled, { ThemeProvider } from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { darkTheme } from 'lib/theme';
import { Auth0Provider } from 'contexts/auth-context';
import history from './history';
import { ShareDealProvider } from 'contexts/modals/ShareDealContext';

const Container = styled.div`
  background-color: ${props => props.theme.colors.dark};
  min-height: 100vh;
`;

const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

toast.configure();

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="openid profile email"
    >
      <ThemeProvider theme={darkTheme}>
        <ShareDealProvider>
          <Container>
            <Router />
          </Container>
        </ShareDealProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export default App;
