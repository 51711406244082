import React from 'react';
import { useMeasure } from 'react-use';
import { Poster as PosterImage } from './styled';
import { Box } from 'rebass/styled-components';
import { memo } from 'react';

export const Poster = memo(props => {
  const [ref, { width }] = useMeasure();

  return (
    <Box ref={ref} width={[1, '10rem']} mr={[0, '2rem']} mb={['2rem', 0]} maxWidth={['21rem', 'unset']}>
      <PosterImage height={width} {...props} />
    </Box>
  );
});

Poster.displayName = 'Poster';
