import Typography from './Typography';
import { Checkbox } from './Checkbox';
import { FormGroup } from './FormGroup';
import { Input, FormInput } from './Input';
import { Label } from './Label';
import { Select } from './Select';
import { Textarea, FormTextArea } from './Textarea';

import { Col } from './Col';
import { Container } from './Container';
import { Row } from './Row';

export * from './Button';
export * from './ErrorDiv';
export * from './Image';
export * from './Loader';
export * from './Logo';
export * from './Spinner';
export * from './Tabs';
export { LoginImmediately } from './LoginImmediately';

const Grid = {
  Col,
  Container,
  Row,
};

const Form = {
  Checkbox,
  Group: FormGroup,
  Input,
  Label,
  Select,
  Textarea,
};

export { Grid, Form, Typography, FormInput, FormTextArea };
