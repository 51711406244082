import React from 'react';
import styled, { css } from 'styled-components';
import { Typography, Grid } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const ImageBg = styled(Box)`
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 40vh;
  padding: 8rem 2rem;
  position: relative;
  width: 100%;

  @media screen and (min-width: 576px) {
    min-height: 50vh;
  }
  @media screen and (min-width: 778px) {
    min-height: 80vh;
  }
`;

const headerStyle = css`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  text-align: center;
`;

const Title = styled(Typography.Heading)`
  ${headerStyle}
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 1rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.sm`
  line-height: 1.2;
  margin-bottom: 2.5rem;
    font-size: 5rem;
  `}
`;

const Subtitle = styled(Typography.Heading)`
  ${headerStyle}
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 4rem;

  ${({ theme }) => theme.mq.sm`
    font-size: 3rem;
  `}
`;

export const Slide = ({ image, subtitle, title }) => {
  return (
    <ImageBg style={{ backgroundImage: `url(${image})` }} justifyContent="center">
      <Grid.Row justifyContent="center" alignItems="center">
        <Grid.Col xs={12} maxWidth="62rem">
          <Title type="h1">{title}</Title>
          <Box maxWidth="45rem" mx="auto">
            <Subtitle type="h2">{subtitle}</Subtitle>
          </Box>
        </Grid.Col>
      </Grid.Row>
    </ImageBg>
  );
};

Slide.propTypes = {
  action: PropTypes.objectOf(PropTypes.string).isRequired,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
