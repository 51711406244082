import React from 'react';
import PropTypes from 'prop-types';

function SvgFacebook({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 14" fill="none" {...props}>
      <path
        d="M7.284.003L5.47 0C3.429 0 2.11 1.352 2.11 3.445v1.589H.285A.285.285 0 000 5.32V7.62c0 .158.128.286.285.286h1.826v5.807c0 .158.128.286.285.286h2.382a.285.285 0 00.285-.286V7.907h2.135a.285.285 0 00.285-.286l.001-2.301a.286.286 0 00-.286-.286H5.063V3.687c0-.647.155-.975.998-.975h1.223a.285.285 0 00.285-.286V.288a.286.286 0 00-.285-.285z"
        fill={fill}
      />
    </svg>
  );
}

SvgFacebook.propTypes = {
  fill: PropTypes.string,
};

SvgFacebook.defaultProps = {
  fill: '#fff',
};

export default SvgFacebook;
