import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Typography } from 'components/atoms';
import { ErrorIcon } from 'components/icons';

export const EmptyState = ({ text }) => {
  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" py="4rem">
      <Box mb="2rem">
        <ErrorIcon width="24rem" height="auto" />
      </Box>

      <Box maxWidth="30rem">
        <Typography.Paragraph textAlign="center" color="white">
          {text}
        </Typography.Paragraph>
      </Box>
    </Flex>
  );
};

EmptyState.propTypes = {
  text: PropTypes.string,
};

EmptyState.defaultProps = {
  text: 'There are no deals available at this time.',
};
